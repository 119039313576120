/** @jsxImportSource @emotion/react */
import React, { ReactNode, useEffect, useState } from "react";
import Modal from "../../../../components/molecules/Modal";
import { useAppDispatch } from "../../../../app/hooks";
import {
  addItemToLoad,
  getBundlesForOrderDetail,
  GetBundlesForOrderDetailRequest,
  removeLoadCartItem,
  updateLoadCartItem,
} from "../../../../store/shipping.actions";
import {
  ErrorResponse,
  LoadLineInventory,
  RequestStatus,
} from "../../../../types/types";
import Toast from "../../../../components/molecules/Toast";
import { numberFormatter } from "../../../../helpers/numberFormat";
import tw from "twin.macro";
import AnimatedLoadingSpinner from "../../../../components/atoms/AnimatedLoadingSpinner";
import Button from "../../../../components/atoms/Button";
import Input from "../../../../components/atoms/Input";
import { css } from "@emotion/react";

type Props = {
  children?: ReactNode;
  onCancel: () => void;
  details: GetBundlesForOrderDetailRequest;
};

const BundleEntryModal: React.FC<Props> = ({ onCancel, details }) => {
  const dispatch = useAppDispatch();
  const [inventory, setInventory] = useState<LoadLineInventory>();
  const [pounds, setPounds] = useState("");
  const [error, setError] = useState<ErrorResponse | string>();
  const [status, setStatus] = useState<RequestStatus>("idle");
  const [saveStatus, setSaveStatus] = useState<RequestStatus>("idle");

  useEffect(() => {
    setStatus("pending");
    dispatch(getBundlesForOrderDetail(details))
      .unwrap()
      .then((result) => {
        setInventory(result);
      })
      .catch((error) => {
        setError(error);
      })
      .finally(() => {
        setStatus("idle");
      });
  }, []);

  const saveHandler = () => {
    const saveParams = {
      allBundles: false,
      inventoryTransactionId: details.inventoryTransactionId,
      loadLineBundles: inventory?.bundles
        ?.map((bundle) => {
          if (!bundle.selected) return null;
          return {
            tag: bundle.tag,
            piecesPerBundle: bundle.piecesPerBundle,
          };
        })
        .filter((item) => item !== null) as [],
    };
    if (saveParams.loadLineBundles.length === 0) {
      if (!details.id) {
        return onCancel();
      } else {
        setSaveStatus("pending");
        dispatch(
          removeLoadCartItem({
            customerId: details.customerId,
            id: details.id,
          })
        )
          .then(() => {
            onCancel();
          })
          .catch((error) => {
            setError(error);
            setSaveStatus("idle");
          });
      }
    } else {
      if (!details.inventoryTransactionId) return;
      if (details.id) {
        // update loadline
        setSaveStatus("pending");
        dispatch(
          updateLoadCartItem({
            id: details.id,
            customerId: details.customerId,
            body: saveParams,
          })
        )
          .unwrap()
          .then(() => {
            onCancel();
          })
          .catch((error) => {
            setError(error);
            setSaveStatus("idle");
          });
      } else {
        // create loadline
        setSaveStatus("pending");
        dispatch(
          addItemToLoad({
            customerId: details.customerId,
            body: saveParams,
          })
        )
          .unwrap()
          .then(() => {
            onCancel();
          })
          .catch((error) => {
            setError(error);
            setSaveStatus("idle");
          });
      }
    }
    return;
  };

  const totalSelected = numberFormatter(
    inventory?.bundles?.reduce((acc, bundle) => {
      return bundle.selected ? acc + bundle.weightPerBundle : acc;
    }, 0)
  );

  const calculateQuickSelection = () => {
    const poundsNumber = parseInt(pounds);
    if (!poundsNumber) {
      setError("Invalid weight input");
      return;
    }
    const bundles = inventory?.bundles?.map((bundle) => ({
      ...bundle,
      selected: false,
    }));
    let weight = 0;
    const selectedBundles =
      bundles?.map((bundle) => {
        if (weight < poundsNumber || weight === 0) {
          weight += bundle.weightPerBundle;
          return { ...bundle, selected: true };
        }
        return bundle;
      }) ?? [];
    setInventory({
      id: details.id,
      inventoryTransactionId: details.inventoryTransactionId,
      bundles: selectedBundles,
    });
  };

  return (
    <Modal
      title={`Bundle Info: ${details.inventoryTransactionId}`}
      onCancel={onCancel}
    >
      {error && (
        <Toast
          type="error"
          onConfirm={() => setError(undefined)}
          message={error}
        />
      )}
      <div className="max-h-[300px] w-[800px] overflow-y-auto">
        <table className="ntp-portal-table w-full">
          <thead>
            <tr>
              <th css={tw`w-[10%]`}>Site</th>
              <th css={tw`w-[38%]`}>Product</th>
              <th css={tw`w-[10%]`}>Length</th>
              <th css={tw`w-[10%]`}>Pcs / Bdl</th>
              <th css={tw`w-[8%]`}>
                Res.
                <br />
                Type
              </th>
              <th css={tw`w-[12%]`}>Weight</th>
              <th css={tw`w-[10%]`}>Load</th>
            </tr>
          </thead>
          <tbody>
            {status === "idle" && inventory?.bundles?.length === 0 && (
              <tr>
                <td colSpan={6} css={tw`text-center`}>
                  No results founds.
                </td>
              </tr>
            )}
            {status !== "pending" &&
              inventory?.bundles?.map((bundle, index) => (
                <tr
                  css={css`
                    & td {
                      border-top: 1px;
                      border-left: 1px;
                    }
                  `}
                  key={index}
                >
                  <td css={tw``}>{bundle.site}</td>
                  <td css={tw``}>{bundle.description}</td>
                  <td className="text-right ">{bundle.lengthDescription}</td>
                  <td className="text-right ">
                    {numberFormatter(bundle.piecesPerBundle)}
                  </td>
                  <td className="text-center ">{bundle.reservationType}</td>
                  <td className="text-right ">
                    {numberFormatter(bundle.weightPerBundle)}
                  </td>
                  <td className="text-center ">
                    <input
                      onChange={(e) => {
                        const isSelected = e.currentTarget?.checked;
                        setInventory((prev) => {
                          if (!prev) return prev;
                          return {
                            id: prev?.id,
                            inventoryTransactionId:
                              prev?.inventoryTransactionId,
                            bundles: prev?.bundles?.map(
                              (bundle, bundleIndex) => {
                                return bundleIndex === index
                                  ? {
                                      ...bundle,
                                      selected: isSelected,
                                    }
                                  : bundle;
                              }
                            ),
                          };
                        });
                      }}
                      type="checkbox"
                      checked={bundle.selected}
                    />
                  </td>
                </tr>
              ))}
          </tbody>
        </table>
      </div>
      {status === "pending" && <AnimatedLoadingSpinner className="mt-2 ml-2" />}
      <div className="p-1 flex justify-between items-center">
        {saveStatus === "pending" && (
          <AnimatedLoadingSpinner message="Saving" className="" />
        )}
        {inventory && (
          <span className="flex-1 text-left font-bold">{inventory?.bundleMessage}</span>
        )}
        <span className="flex-1 text-right font-bold">{`Total selected weight: ${totalSelected} lbs`}</span>
      </div>
      <div className="flex justify-end gap-4 mt-2">
        <div className="flex items-center gap-2 mr-8">
          <span className="font-bold">Quick lbs. selection: </span>
          <Input
            value={pounds}
            maxLength={7}
            onChange={(e) => {
              if (!/^\d*$/.test(e.target.value)) return;
              setPounds(e.target.value);
            }}
            onKeyDown={(e) => {
              if (e.key === "Enter") calculateQuickSelection();
            }}
            className="max-w-[9ch]"
          />
          <Button
            onClick={() => {
              calculateQuickSelection();
            }}
          >
            Select
          </Button>
        </div>
        <Button disabled={status === "pending"} onClick={saveHandler}>
          Save
        </Button>
        <Button onClick={onCancel}>Cancel</Button>
      </div>
    </Modal>
  );
};

export default BundleEntryModal;
