/** @jsxImportSource @emotion/react */
import React, { ComponentProps, ReactNode } from "react";
import tw from "twin.macro";
import { numberFormatter } from "../../../../helpers/numberFormat";

type Props = {
  children?: ReactNode;
  totals?: {
    poundsOrdered: number;
    poundsReadyToLoad: number;
    poundsBalance: number;
  };
} & ComponentProps<"div">;

const Totals: React.FC<Props> = ({ children, totals, ...remainingProps }) => {
  return (
    <div css={tw`grid grid-cols-5`} {...remainingProps}>
      <div css={tw`font-semibold text-nucor-gray col-span-2 text-right`}>
        {children ? children : "Totals"}
      </div>
      <div css={tw`text-center`}>
        {numberFormatter(totals?.poundsOrdered ?? 0)}
      </div>
      <div css={tw`text-center`}>
        {numberFormatter(totals?.poundsBalance ?? 0)}
      </div>
      <div css={tw`text-center`}>
        {numberFormatter(totals?.poundsReadyToLoad ?? 0)}
      </div>
    </div>
  );
};

export default Totals;
