/** @jsxImportSource @emotion/react */
import React, { ReactNode, useContext, useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../../app/hooks";
import ButtonSelect from "../../../../components/atoms/ButtonSelect";
import Select from "../../../../components/atoms/Select";
import TabGroupSubGroup from "../../../../components/atoms/TabGroupSubGroup";
import { selectCurrentCustomerId } from "../../../../store/customer.reducer";
import { searchInvoiceDocuments } from "../../../../store/document.actions";
import {
  clearDocumentErrors,
  selectInvoiceDocumentsSelectionData,
  updateInvoiceSearchCriteria,
} from "../../../../store/document.reducer";
import { selectDefaultMill, selectCurrentAuthorities } from "../../../../store/user.reducer";
import Toast from "../../../../components/molecules/Toast";
import { PDFContext } from "./InvoicesList";
import { useSearchParams } from "react-router-dom";
import { hasAuthority } from "../../../../helpers/hasAuthority";

type InvoiceDocumentsBrowseCriteria = {
  myCustomers?: boolean;
  dateRangeType?: string;
  shipToCustomerId?: string;
  sites?: string[];
  invoiceSearchStatus: string;
  siteKey?: string;
  salesPersonCode?: string
};

type Props = {
  children?: ReactNode;
};

const InvoicesBrowse: React.FC<Props> = () => {
  const dispatch = useAppDispatch();

  const currentAuthorities = useAppSelector(selectCurrentAuthorities);
  const isInternal = hasAuthority(["internal"], currentAuthorities);
  
  const customerId = useAppSelector(selectCurrentCustomerId);
  const defaultMill = useAppSelector(selectDefaultMill);
  const invoiceDocumentsSelectionData = useAppSelector(
    selectInvoiceDocumentsSelectionData
  );
  const { searchCriteria, error } = useAppSelector(
    (state) => state.document.invoices
  );
  const ctx = useContext(PDFContext);

  const [searchParams] = useSearchParams();
  const repParam = isInternal ? searchParams.get("salesRep") : undefined;
  const statusParam = isInternal ? searchParams.get("status") : undefined;
  const dateParam = isInternal ? searchParams.get("dateRange") : undefined;
  const siteParam = isInternal ? searchParams.get("site") : undefined;

  const [salesRep, setSalesRep] = useState<string>(repParam ?? "");
  const [customerSearchType, setCustomerSearchType] = useState<string>(repParam ? "rep" : "false");

  const initInvoiceSearchStatus = statusParam ? statusParam : (searchCriteria.invoiceSearchStatus ?? 'all');
  const initSite = siteParam ? siteParam : (searchCriteria.siteKey ?? defaultMill ?? "ALL");
  const initDateRangeType = dateParam ? dateParam : (searchCriteria.dateRangeType ?? "last30Days");

  const [invoiceSearchStatus, setInvoiceSearchStatus] = useState<string>(initInvoiceSearchStatus);
  const [site, setSite] = useState<string>(initSite);
  const [dateRangeType, setDateRangeType] = useState<string>(initDateRangeType);

  const shipToCustomerId = searchCriteria.shipToCustomerId ?? "all";

  const checkForAllCustomers = (value: string) => {
    return ("true" == value || "rep" == value);
  };

  const currentCriteria = {
    ...searchCriteria,
    myCustomers: checkForAllCustomers(customerSearchType),
    invoiceSearchStatus: invoiceSearchStatus,
    dateRangeType,
    shipToCustomerId:
      shipToCustomerId === "all" || checkForAllCustomers(customerSearchType)
        ? undefined
        : shipToCustomerId,
    sites:
      site === "ALL"
        ? undefined
        : invoiceDocumentsSelectionData?.sites.find((item) => item.key === site)
            ?.sites,
    salesPersonCode: 
      salesRep === "" || "rep" !== customerSearchType
      ? undefined
      : salesRep,
  };

  const shipTos =
  invoiceDocumentsSelectionData?.shipTos.map((shipTo) => {
    return {
      value: shipTo.id,
      label: <span>{`${shipTo.id} - ${shipTo.name}`}</span>,
    };
  }) ?? [];

  const salesReps =
  invoiceDocumentsSelectionData?.salesReps.map((rep) => {
    return {
      value: rep.key,
      label: rep.contactName
    };
  }) ?? [];

  const checkSalesRep = (value: string) => {
    const rep = salesReps.find((rep) => rep.value === value);
    return rep !== undefined;
  };

  const checkStatus = (value: string) => {
    const statuses = invoiceDocumentsSelectionData?.invoiceStatuses ?? [];
    const status = statuses.find((s) => s.id === value);
    return status !== undefined;
  };

  const checkDateRange = (value: string) => {
    const dateRanges = invoiceDocumentsSelectionData?.dateSelections ?? [];
    const dateRange = dateRanges.find((dr) => dr.id === value);
    return dateRange !== undefined;
  };

  const checkSite = (value: string) => {
    const sites = invoiceDocumentsSelectionData?.sites ?? [];
    const site = sites.find((s) => s.key === value);
    return site !== undefined;
  };

  useEffect(() => {
    if (!customerId || !invoiceDocumentsSelectionData) return;

    if (customerSearchType === "rep" && salesRep !== "" && !checkSalesRep(salesRep)) {
      return;
    }

    if (!checkStatus(invoiceSearchStatus)) {
      return;
    }

    if (!checkDateRange(dateRangeType)) {
      return;
    }

    if (site !== "ALL" && !checkSite(site)) {
      return;
    }

    // Close pdf if open
    if (ctx?.file) {
      ctx.setFile(null);
    }
    dispatch(searchInvoiceDocuments({ customerId, criteria: currentCriteria }));
  }, [searchCriteria, invoiceDocumentsSelectionData]);

  const onSelectionsChanged = (criteria: InvoiceDocumentsBrowseCriteria) => {
    if (!customerId) return;
    dispatch(updateInvoiceSearchCriteria({ ...criteria, page: 1 }));
  };

  return (
    <>
      {error && (
        <Toast
          type="error"
          message={error}
          onConfirm={() => dispatch(clearDocumentErrors())}
        />
      )}
      <TabGroupSubGroup title="Show">
        <Select
          minWidth="22ch"
          name="showBy"
          data={invoiceDocumentsSelectionData?.customerSearchType.map(
            (item) => ({
              value: item.id,
              label: item.description,
            })
          )}
          onChange={(value: string) => {
            setCustomerSearchType(value);
            onSelectionsChanged({
              ...currentCriteria,
              myCustomers: checkForAllCustomers(value),
            });
          }}
          value={customerSearchType}
        />
      </TabGroupSubGroup>
      {!checkForAllCustomers(customerSearchType) && (
        <TabGroupSubGroup title="By Ship To">
          <Select
            minWidth="22ch"
            maxWidth="22ch"
            name="byShipTo"
            data={[{ value: "all", label: "All" }, ...shipTos]}
            onChange={(value: string) => {
              onSelectionsChanged({
                ...currentCriteria,
                shipToCustomerId: value === "all" ? undefined : value,
              });
            }}
            value={shipToCustomerId}
          />
        </TabGroupSubGroup>
      )}
      {"rep" === customerSearchType && (
        <TabGroupSubGroup title="By Sales Rep">
          <Select
            minWidth="22ch"
            maxWidth="22ch"
            name="bySalesRep"
            data={[{ value: "", label: "All" }, ...salesReps]}
            onChange={(value: string) => {
              setSalesRep(value);
              onSelectionsChanged({
                ...currentCriteria,
                salesPersonCode: value === "" ? undefined : value,
              });
            }}
            value={salesRep}
          />
        </TabGroupSubGroup>
      )}

      <TabGroupSubGroup title="By Invoice Date">
        {invoiceDocumentsSelectionData?.dateSelections.map((date, index) => {
          return (
            <ButtonSelect
              key={index}
              onClick={() => {
                setDateRangeType(date.id);
                onSelectionsChanged({
                  ...currentCriteria,
                  dateRangeType: date.id,
                });
              }}
              isActive={dateRangeType === date.id}
            >
              {date.description}
            </ButtonSelect>
          );
        })}
      </TabGroupSubGroup>
      <TabGroupSubGroup title="By Mill">
        <ButtonSelect
          isActive={site === "ALL"}
          onClick={() => {
            setSite("ALL");
            onSelectionsChanged({
              ...currentCriteria,
              sites: undefined,
              siteKey: "ALL",
            });
          }}
        >
          All
        </ButtonSelect>
        {invoiceDocumentsSelectionData?.sites.map((item, index) => {
          return (
            <ButtonSelect
              isActive={site === item.key}
              onClick={() => {
                setSite(item.key);
                onSelectionsChanged({
                  ...currentCriteria,
                  siteKey: item.key,
                });
              }}
              key={index}
            >
              {item.description}
            </ButtonSelect>
          );
        })}
      </TabGroupSubGroup>
      <TabGroupSubGroup title="By Invoice Status">
        {invoiceDocumentsSelectionData?.invoiceStatuses.map((status, index) => (
          <ButtonSelect
            key={index}
            isActive={invoiceSearchStatus === status.id}
            onClick={() => {
              setInvoiceSearchStatus(status.id);
              onSelectionsChanged({
                ...currentCriteria,
                invoiceSearchStatus: status.id,
              });
            }}
          >
            {status.description}
          </ButtonSelect>
        ))}
      </TabGroupSubGroup>
    </>
  );
};

export default InvoicesBrowse;
