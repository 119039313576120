/** @jsxImportSource @emotion/react */
import React, { ReactNode, useContext, useState } from "react";
import tw from "twin.macro";
import { useAppDispatch, useAppSelector } from "../../../../app/hooks";
import TextButton from "../../../../components/atoms/TextButton";
import { numberFormatter } from "../../../../helpers/numberFormat";
import {
  fetchOrderDocumentDetailPDF,
  FetchOrderDocumentDetailPDFRequest,
} from "../../../../store/document.actions";
import { OrderSummaryPagedResult } from "../../../../types/types";
import { ordersSort } from "../../../../store/document.reducer";
import TableSortButton from "../../../../components/molecules/TableSortButton";
import Toast from "../../../../components/molecules/Toast";
import { formatDateToShort } from "../../../../helpers/dateHelpers";
import { PDFContext } from "./OrdersList";

type Props = {
  children?: ReactNode;
  summaries?: OrderSummaryPagedResult;
};

const OrdersResults: React.FC<Props> = ({ summaries }) => {
  const dispatch = useAppDispatch();
  const searchCriteria = useAppSelector(
    (state) => state.document.orders.searchCriteria
  );
  const [status, setStatus] = useState("idle");
  const [errorMessage, setErrorMessage] = useState("");
  const ctx = useContext(PDFContext);

  const viewOrderHandler = (params: FetchOrderDocumentDetailPDFRequest) => {
    setStatus("pending");
    setErrorMessage("");
    dispatch(fetchOrderDocumentDetailPDF(params))
      .unwrap()
      .then((result) => {
        ctx?.setFile(result);
        setStatus("success");
      })
      .catch((error) => {
        setStatus("error");
        ctx?.setFile(null);
        setErrorMessage(error);
      });
  };

  return (
    <>
      {errorMessage && (
        <Toast
          type="error"
          message={errorMessage}
          onConfirm={() => setErrorMessage("")}
        />
      )}
      <table className="ntp-portal-table" css={[tw`w-full text-xs`]}>
        <thead>
          <tr>
            <th css={tw`w-[11%]`}>
              <TableSortButton
                field="billingCustomerId"
                sort={searchCriteria}
                onClick={() => dispatch(ordersSort("billingCustomerId"))}
              >
                Customer Id
              </TableSortButton>
            </th>
            <th css={tw`w-[11%]`}>
              <TableSortButton
                field="customerPONumber"
                sort={searchCriteria}
                onClick={() => dispatch(ordersSort("customerPONumber"))}
              >
                PO Number
              </TableSortButton>
            </th>
            <th>
              <TableSortButton
                field="salesOrderNumber"
                sort={searchCriteria}
                onClick={() => dispatch(ordersSort("salesOrderNumber"))}
              >
                Sales Order
              </TableSortButton>
            </th>
            <th css={tw`w-[9%]`}>
              <TableSortButton
                field="site"
                sort={searchCriteria}
                onClick={() => dispatch(ordersSort("site"))}
              >
                Mill
              </TableSortButton>
            </th>
            <th css={tw`w-[11%]`}>
              <TableSortButton
                field="orderDate"
                sort={searchCriteria}
                onClick={() => dispatch(ordersSort("orderDate"))}
              >
                Order Date
              </TableSortButton>
            </th>
            <th css={tw`w-[6%]`}>
              <TableSortButton
                field="totalNumItems"
                sort={searchCriteria}
                onClick={() => dispatch(ordersSort("totalNumItems"))}
              >
                Items Ord
              </TableSortButton>
            </th>
            <th>
              <TableSortButton
                field="totalWeightOrdered"
                sort={searchCriteria}
                onClick={() => dispatch(ordersSort("totalWeightOrdered"))}
              >
                Wgt Ord
              </TableSortButton>
            </th>
            <th>
              <TableSortButton
                field="totalWeightBalance"
                sort={searchCriteria}
                onClick={() => dispatch(ordersSort("totalWeightBalance"))}
              >
                Wgt Bal
              </TableSortButton>
            </th>
            <th>
              <TableSortButton
                field="status"
                sort={searchCriteria}
                onClick={() => dispatch(ordersSort("status"))}
              >
                Status
              </TableSortButton>
            </th>
            <th css={tw`w-[5%]`}>
              <TableSortButton
                field="portalOrder"
                sort={searchCriteria}
                onClick={() => dispatch(ordersSort("portalOrder"))}
              >
                Portal Ord
              </TableSortButton>
            </th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>

          {summaries?.list && summaries?.list.length > 0 ? (
            summaries?.list.map((order, index) => (
              <tr key={index}>
                <td>{order.billingCustomerId}</td>
                <td>{order.customerPONumber}</td>
                <td>{order.salesOrderNumber}</td>
                <td>{order.site}</td>
                <td>
                  {formatDateToShort(order.orderDate)}
                </td>
                <td css={tw`text-right w-[7%]`}>
                  {numberFormatter(order.totalNumItems)}
                </td>
                <td css={tw`text-right`}>
                  {numberFormatter(order.totalWeightOrdered)}
                </td>
                <td css={tw`text-right`}>
                  {numberFormatter(order.totalWeightBalance)}
                </td>
                <td>{order.status}</td>
                <td css={tw`text-center`}>{order.portalOrder ? "Y" : "N"}</td>
                <td css={tw`text-center`}>
                  {order.hideDocumentLink ? null : (
                    <TextButton
                      disabled={status === "pending"}
                      onClick={() =>
                        viewOrderHandler({
                          customerId: order.billingCustomerId,
                          id: order.salesOrderNumber,
                        })
                      }
                    >
                      View
                    </TextButton>
                  )}
                </td>
              </tr>
            ))
          ) : (
            <tr>
              <td css={tw`text-nucor-gray !px-2 !py-3 hover:!bg-white`} colSpan={11}>
                No Orders found for the selected criteria
              </td>
            </tr>
          )}
        </tbody>
      </table>
    </>
  );
};

export default OrdersResults;
