import React, { ReactNode } from "react";
import InquiryBrowse from "./InquiryBrowse";
import InquirySearch from "./InquirySearch";
import BrowseSearch from "../BrowseSearch";
import { useAppDispatch } from "../../../../app/hooks";
import { clearQuoteSearchCriteria } from "../../../../store/document.reducer";

type Props = {
  children?: ReactNode;
};

const InquiryLeft: React.FC<Props> = () => {
  const dispatch = useAppDispatch();

  return (
    <BrowseSearch
      browse={<InquiryBrowse />}
      search={<InquirySearch />}
      browseOnClick={() => {
        dispatch(clearQuoteSearchCriteria());
      }}
    />
  );
};

export default InquiryLeft;
