/** @jsxImportSource @emotion/react */
import React, { ReactNode, useContext, useState } from "react";
import tw from "twin.macro";
import { useAppDispatch, useAppSelector } from "../../../../app/hooks";
import TextButton from "../../../../components/atoms/TextButton";
import SortDirection from "../../../../components/molecules/SortDirection";
import {
  exportTestCertPDF,
  ExportTestCertPDFRequest,
} from "../../../../store/document.actions";
import { testCertSort } from "../../../../store/document.reducer";
import { TestCertPagedResult } from "../../../../types/types";
import Toast from "../../../../components/molecules/Toast";
import { formatDateToShort } from "../../../../helpers/dateHelpers";
import { PDFContext } from "./CertsList";

type Props = {
  children?: ReactNode;
  testCerts?: TestCertPagedResult;
};

const CertsResults: React.FC<Props> = ({ testCerts }) => {
  const dispatch = useAppDispatch();
  const { searchCriteria } = useAppSelector((state) => state.document.testCert);
  const ctx = useContext(PDFContext);
  const [status, setStatus] = useState("idle");
  const [errorMessage, setErrorMessage] = useState("");

  const pdfHandler = (params: ExportTestCertPDFRequest) => {
    setStatus("pending");
    setErrorMessage("");
    dispatch(exportTestCertPDF(params))
      .unwrap()
      .then((result) => {
        ctx?.setFile(result);
        setStatus("success");
      })
      .catch((error) => {
        setStatus("error");
        setErrorMessage(error);
      });
  };

  return (
    <>
      {errorMessage && (
        <Toast
          type="error"
          message={errorMessage}
          onConfirm={() => setErrorMessage("")}
        />
      )}
      <table className="ntp-portal-table" css={tw`w-full text-xs`}>
        <thead>
          <tr>
            <th>
              <button onClick={() => dispatch(testCertSort("mtrCreatedDate"))}>
                Cert Date
                {searchCriteria.sort === "mtrCreatedDate" && (
                  <SortDirection dir={searchCriteria.dir} />
                )}
              </button>
            </th>
            <th>
              <button
                onClick={() => dispatch(testCertSort("materialCertificateId"))}
              >
                Cert Number
                {searchCriteria.sort === "materialCertificateId" && (
                  <SortDirection dir={searchCriteria.dir} />
                )}
              </button>
            </th>
            <th>
              <button onClick={() => dispatch(testCertSort("site"))}>
                Mill
                {searchCriteria.sort === "site" && (
                  <SortDirection dir={searchCriteria.dir} />
                )}
              </button>
            </th>
            <th>
              <button
                onClick={() => dispatch(testCertSort("customerPONumber"))}
              >
                PO Number
                {searchCriteria.sort === "customerPONumber" && (
                  <SortDirection dir={searchCriteria.dir} />
                )}
              </button>
            </th>
            <th>
              <button
                onClick={() => dispatch(testCertSort("shippingCustomerId"))}
              >
                Ship To
                {searchCriteria.sort === "shippingCustomerId" && (
                  <SortDirection dir={searchCriteria.dir} />
                )}
              </button>
            </th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          {testCerts?.list && testCerts.list.length > 0 ? (
            testCerts?.list.map((cert, index) => (
              <tr key={index}>
                <td>
                  {formatDateToShort(cert.mtrCreatedDate)}
                </td>
                <td>{cert.materialCertificateId}</td>
                <td>{cert.site}</td>
                <td>{cert.customerPONumber}</td>
                <td>{`${cert.shippingCustomerId}-${cert.customerName}`}</td>
                <td css={tw`text-center`}>
                  <TextButton
                    disabled={status === "pending"}
                    onClick={() =>
                      pdfHandler({
                        customerId: cert.billingCustomerId ?? "",
                        id: cert.materialCertificateId,
                      })
                    }
                  >
                    View
                  </TextButton>
                </td>
              </tr>
            ))
          ) : (
            <tr>
              <td css={tw`text-nucor-gray !px-2 !py-3 hover:!bg-white`} colSpan={6}>
                No test certificates found for the selected criteria
              </td>
            </tr>
          )} 
        </tbody>
      </table>
    </>
  );
};

export default CertsResults;
