/** @jsxImportSource @emotion/react */
import React, { ReactNode, useContext } from "react";
import tw from "twin.macro";
import { useAppDispatch, useAppSelector } from "../../../../app/hooks";
import Loading from "../../../../components/atoms/Loading";
import Pagination from "../../../../components/molecules/PaginationComponent";
import OrdersResults from "./OrdersResults";
import { updateOrdersPagination } from "../../../../store/document.reducer";
import PDFEmbed from "../../../../components/molecules/PDFEmbed";
import { PDFContext } from "./OrdersList";
import TextButton from "../../../../components/atoms/TextButton";

type Props = {
  children?: ReactNode;
};

const OrdersContent: React.FC<Props> = () => {
  const dispatch = useAppDispatch();
  const { status } = useAppSelector((state) => state.document.orders);
  const summaries = useAppSelector(
    (state) => state.document.orders.ordersResults
  );
  const { searchCriteria } = useAppSelector((state) => state.document.orders);
  const ctx = useContext(PDFContext);

  return (
    <div>
      <div css={tw`text-white font-bold bg-nucor-green text-xs flex justify-between py-1 px-2`}>
        <span className="uppercase w-16">Orders</span>
        {ctx?.file && (
          <TextButton onClick={() => ctx?.setFile(null)} css={tw`text-white`}>
            Back to List
          </TextButton>
        )}
        <span className="w-16"></span>
      </div>
      <div css={[tw`relative`, ctx?.file && tw`hidden`]}>
        {status === "pending" && <Loading />}

        <div css={tw` mt-2`}>
          {summaries && summaries.count > 0 && (
            <Pagination
              currentPage={searchCriteria.page}
              onChange={(value) => dispatch(updateOrdersPagination(value))}
              items={summaries.count}
              isLoading={false}
            />
          )}
        </div>
        <OrdersResults summaries={summaries} />
      </div>
      {ctx?.file && (
        <PDFEmbed
          setFile={ctx?.setFile}
          onCancel={() => ctx?.setFile(null)}
          file={ctx?.file}
        />
      )}
    </div>
  );
};

export default OrdersContent;
