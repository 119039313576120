/** @jsxImportSource @emotion/react */
import React, { ReactNode, useState } from "react";
import tw from "twin.macro";
import { useAppDispatch, useAppSelector } from "../../../../app/hooks";
import {
  quickQuoteSort,
  updateQuickQuotePagination,
} from "../../../../store/document.reducer";
import { fetchQuickQuotePDF } from "../../../../store/document.actions";
import { selectCurrentCustomerId } from "../../../../store/customer.reducer";
import Loading from "../../../../components/atoms/Loading";
import TableSortButton from "../../../../components/molecules/TableSortButton";
import Toast from "../../../../components/molecules/Toast";
import Pagination from "../../../../components/molecules/PaginationComponent";
import TextButton from "../../../../components/atoms/TextButton";
import { numberFormatter } from "../../../../helpers/numberFormat";
import { hasAuthority } from "../../../../helpers/hasAuthority";
import { selectCurrentAuthorities } from "../../../../store/user.reducer";
import { useNavigate } from 'react-router-dom';
import AnimatedSpinner from "../../../../components/atoms/AnimatedSpinner";

type Props = {
  children?: ReactNode;
};

const QuoteResults: React.FC<Props> = () => {
  const dispatch = useAppDispatch();
  const customerId = useAppSelector(selectCurrentCustomerId);
  const results = useAppSelector(
    (state) => state.document.quickquote.quoteResults
  );
  const { searchCriteria } = useAppSelector(
    (state) => state.document.quickquote
  );
  const { status } = useAppSelector((state) => state.document.quickquote);
  const currentAuthorities = useAppSelector(selectCurrentAuthorities);
  const isInternal = hasAuthority("internal", currentAuthorities);
  const [errorMessage, setErrorMessage] = useState("");
  const [loadingPdfNumber, setLoadingPdfNumber] = useState(0);
  const navigate = useNavigate();

  const pdfHandler = (quoteId: number) => {
    if (!customerId) return;
    setLoadingPdfNumber(quoteId);
    setErrorMessage("");
    dispatch(
      fetchQuickQuotePDF({
        quoteId: quoteId,
        pdfType: "pdf",
      })
    )
      .unwrap()
      .catch((error) => {
        setErrorMessage(error);
      }).finally(() => setLoadingPdfNumber(0));
  };

  const idClickHandler = (quoteId: number) => {
    isInternal ? navigate(`./${quoteId}`) : pdfHandler(quoteId);
  };

  return (
    <>
      {errorMessage && (
        <Toast
          type="error"
          message={errorMessage}
          onConfirm={() => setErrorMessage("")}
        />
      )}
      <div css={tw`relative`}>
        {status === "pending" && <Loading />}
        <Pagination
          currentPage={searchCriteria.page}
          isLoading={status === "pending"}
          items={results?.count ?? 0}
          onChange={(value) => {
            dispatch(updateQuickQuotePagination(value));
          }}
        />
        <table className="ntp-portal-table" css={tw`w-full text-xs`}>
          <thead>
            <tr>
              <th>
                <TableSortButton
                  sort={searchCriteria}
                  field="id"
                  onClick={(field) => dispatch(quickQuoteSort(field))}
                >
                  Id
                </TableSortButton>
              </th>
              <th>
                <TableSortButton
                  sort={searchCriteria}
                  field="createdDate"
                  onClick={(field) => dispatch(quickQuoteSort(field))}
                >
                  Created Date
                </TableSortButton>
              </th>
              <th>
                {" "}
                <TableSortButton
                  sort={searchCriteria}
                  field="jobName"
                  onClick={(field) => dispatch(quickQuoteSort(field))}
                >
                  Job Name
                </TableSortButton>
              </th>
              <th>
                {" "}
                <TableSortButton
                  sort={searchCriteria}
                  field="billingCustomerId"
                  onClick={(field) => dispatch(quickQuoteSort(field))}
                >
                  Customer
                </TableSortButton>
              </th>
              <th>
                {" "}
                <TableSortButton
                  sort={searchCriteria}
                  field="customerRefNumber"
                  onClick={(field) => dispatch(quickQuoteSort(field))}
                >
                  Customer Ref#
                </TableSortButton>
              </th>
              <th>
                {" "}
                <TableSortButton
                  sort={searchCriteria}
                  field="status"
                  onClick={(field) => dispatch(quickQuoteSort(field))}
                >
                  Status
                </TableSortButton>
              </th>
              <th> Total Weight</th>
            </tr>
          </thead>
          <tbody>
            {results?.list && results.list.length > 0 ? (
              results?.list.map((quote) => (
                <tr key={quote.id}>
                  <td>
                    <TextButton onClick={() => idClickHandler(quote.id)} disabled={loadingPdfNumber !== 0}>
                      <div css={tw`relative`}>
                        {quote.formattedId}
                        {loadingPdfNumber === quote.id && (
                          <div css={tw`absolute -right-6 top-0`}>
                            <AnimatedSpinner />
                          </div>
                        )}
                      </div>
                    </TextButton>
                  </td>
                  <td>{quote.formattedCreatedDate}</td>
                  <td>{quote.jobName}</td>
                  <td>
                    {quote.prospectiveCustomer
                      ? quote.customerName
                      : `${quote.billingCustomerId} - ${quote.customerName}`}
                  </td>
                  <td>{quote.customerRefNumber}</td>
                  <td>{quote.status}</td>
                  <td css={tw`text-right!`}>
                    {quote.totalWeight !== 0 &&
                      numberFormatter(quote.totalWeight)}
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td css={tw`text-nucor-gray !px-2 !py-3 hover:!bg-white`} colSpan={7}>
                No quotes found for the selected criteria
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
      
    </>
  );
};

export default QuoteResults;
