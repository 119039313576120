let observers: Array<() => void> = [];

export default Object.freeze({
  notify: () => observers.forEach((obs) => obs()),
  subscribe: (func: () => void) => {
    observers.push(func);
  },
  unsubscribe: (func: () => void) => {
    observers = observers.filter((obs) => obs === func);
  },
});
