import { createAsyncThunk } from "@reduxjs/toolkit";
import { axiosWithAuth } from "../app/axios";
import { AddUserForm } from "../features/admin/users/add_user/addUserForm";
import { PersonalInfoForm } from "../features/admin/users/edit_user/personalInfoForm";
import { objectToQueryString } from "../helpers/objectToQueryString";
import {
  CustomerIndex,
  UserAdminCreateView,
  UserAdminReadView,
  UserAdminUpdateView,
  UserReadView,
  AccessControl,
  ErrorResponse,
  UserUpdateFormSetup,
} from "../types/types";
import { setCurrentResultsPage } from "./admin.reducer";
import { setAccessControl } from "./user.reducer";
import { AxiosError } from "axios";
import { isBlobError } from "../types/predicates";
import { blobToErrorResponse } from "../helpers/blobToErrorResponse";
import { downloadFile } from "../helpers/downloadExcel";

export type UserSearchRequest = {
  userId?: string;
  customerId?: string;
  firstName?: string;
  lastName?: string;
  includeDisabled?: boolean;
  email?: string;
  searchType?: string;
};

export const searchUsers = createAsyncThunk<
  UserReadView[],
  UserSearchRequest,
  { rejectValue: string | ErrorResponse }
>("admin/searchUsers", async (searchDetails, { rejectWithValue, dispatch }) => {
  try {
    dispatch(setCurrentResultsPage(0));
    const response = await axiosWithAuth.get(
      `${process.env.REACT_APP_API_URL}/admin/user/search${objectToQueryString(
        searchDetails
      )}`
    );
    return response.data;
  } catch (error: unknown) {
    if (error instanceof AxiosError) {
      switch (error.response?.status) {
        case 400:
          return rejectWithValue("Invalid criteria");
        case 401:
          return rejectWithValue("Unauthorized");
        case 403:
          return rejectWithValue("Forbidden");
        case 423:
          return rejectWithValue(error.response?.data);
        case 500:
          return rejectWithValue("Internal server error");
        default:
          return rejectWithValue("Error fetching all users.");
      }
    } else {
      return rejectWithValue("Error fetching all users.");
    }
  }
});

export const getUserInfo = createAsyncThunk<
  UserAdminReadView,
  number,
  { rejectValue: string | ErrorResponse }
>("admin/getUserInfo", async (id, { rejectWithValue }) => {
  try {
    const response = await axiosWithAuth.get(
      `${process.env.REACT_APP_API_URL}/admin/user/${id}`
    );
    return response.data;
  } catch (error: unknown) {
    if (error instanceof AxiosError) {
      switch (error.response?.status) {
        case 400:
          return rejectWithValue("Invalid id supplied");
        case 401:
          return rejectWithValue("Unauthorized");
        case 403:
          return rejectWithValue("Forbidden");
        case 404:
          return rejectWithValue(error.response?.data);
        case 423:
          return rejectWithValue(error.response?.data);
        case 500:
          return rejectWithValue("Internal server error");
        default:
          return rejectWithValue("Error getting user from admin endpoint.");
      }
    } else {
      return rejectWithValue("Error getting user from admin endpoint.");
    }
  }
});

export const searchCustomers = createAsyncThunk<
  CustomerIndex[],
  string,
  { rejectValue: string | ErrorResponse }
>("admin/searchCustomers", async (searchTerm, { rejectWithValue }) => {
  try {
    const response = await axiosWithAuth(
      `${process.env.REACT_APP_API_URL}/admin/user/search_customers?fuzzyName=${searchTerm}`
    );
    return response.data;
  } catch (error: unknown) {
    if (error instanceof AxiosError) {
      switch (error.response?.status) {
        case 400:
          return rejectWithValue("Invalid criteria");
        case 401:
          return rejectWithValue("Unauthorized");
        case 403:
          return rejectWithValue("Forbidden");
        case 423:
          return rejectWithValue(error.response?.data);
        case 500:
          return rejectWithValue("Internal server error");
        default:
          return rejectWithValue("Error searching customers.");
      }
    } else {
      return rejectWithValue("Error searching customers.");
    }
  }
});

export const addUser = createAsyncThunk<
  UserAdminCreateView,
  AddUserForm,
  { rejectValue: string | ErrorResponse }
>("admin/addUser", async (user: AddUserForm, { rejectWithValue }) => {
  try {
    const response = await axiosWithAuth.post(
      `${process.env.REACT_APP_API_URL}/admin/user`,
      user
    );
    return response.data;
  } catch (error: unknown) {
    if (error instanceof AxiosError) {
      switch (error.response?.status) {
        case 400:
          return rejectWithValue(error.response?.data);
        case 401:
          return rejectWithValue("Unauthorized");
        case 403:
          return rejectWithValue("Forbidden");
        case 423:
          return rejectWithValue(error.response?.data);
        case 500:
          return rejectWithValue("Internal server error");
        default:
          return rejectWithValue("There was an issue creating the user");
      }
    } else {
      return rejectWithValue("There was an issue creating the user");
    }
  }
});

export const updateUser = createAsyncThunk<
  UserAdminUpdateView,
  PersonalInfoForm,
  { rejectValue: string | ErrorResponse }
>("admin/updateUser", async (user: PersonalInfoForm, { rejectWithValue }) => {
  try {
    const response = await axiosWithAuth.put(
      `${process.env.REACT_APP_API_URL}/admin/user/${user.userId}`,
      user
    );
    return response.data;
  } catch (error: unknown) {
    if (error instanceof AxiosError) {
      switch (error.response?.status) {
        case 400:
          return rejectWithValue(error.response?.data);
        case 401:
          return rejectWithValue("Unauthorized");
        case 403:
          return rejectWithValue("Forbidden");
        case 404:
          return rejectWithValue(error.response?.data);
        case 423:
          return rejectWithValue(error.response?.data);
        case 500:
          return rejectWithValue("Internal server error");
        default:
          return rejectWithValue("There was an issue updating the user");
      }
    } else {
      return rejectWithValue("There was an issue updating the user");
    }
  }
});

export const updateAccessControl = createAsyncThunk<
  void,
  AccessControl,
  { rejectValue: string | ErrorResponse }
>(
  "admin/updateAccessControl",
  async (settings, { dispatch, rejectWithValue }) => {
    try {
      const response = await axiosWithAuth.post(
        `${process.env.REACT_APP_API_URL}/admin/update_access_control`,
        settings
      );
      dispatch(setAccessControl(response.data));
    } catch (error: unknown) {
      if (error instanceof AxiosError) {
        switch (error.response?.status) {
          case 401:
            return rejectWithValue("Unauthorized");
          case 403:
            return rejectWithValue("Forbidden");
          case 423:
            return rejectWithValue(error.response?.data);
          case 500:
            return rejectWithValue("Internal server error");
          default:
            return rejectWithValue("Error updating access control settings.");
        }
      } else {
        return rejectWithValue("Error updating access control settings.");
      }
    }
  }
);

export const exportUsersAsExcel = createAsyncThunk<
  void,
  UserSearchRequest,
  { rejectValue: string | ErrorResponse }
>("admin/exportUsersAsExcel", async (details, { rejectWithValue }) => {
  try {
    const response = await axiosWithAuth.get(
      `${
        process.env.REACT_APP_API_URL
      }/admin/user/search/excel${objectToQueryString(details)}`,
      { responseType: "blob" }
    );
    const file = new Blob([response.data], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });
    const filename = response.headers["content-disposition"].split("=")[1];
    downloadFile(file, filename);
    return;
  } catch (error: unknown) {
    if (error instanceof AxiosError) {
      if (isBlobError(error.response?.data) && error.response) {
        error.response.data = await blobToErrorResponse(error.response.data);
      }
      switch (error.response?.status) {
        case 400:
          return rejectWithValue("Invalid criteria");
        case 401:
          return rejectWithValue("Unauthorized");
        case 403:
          return rejectWithValue("Forbidden");
        case 423:
          return rejectWithValue(error.response?.data);
        case 500:
          return rejectWithValue("Internal server error");
        default:
          return rejectWithValue(
            "There was an issue retrieving excel sheet of users."
          );
      }
    } else {
      return rejectWithValue(
        "There was an issue retrieving excel sheet of users."
      );
    }
  }
});

export const getUserInfoFormSetup = createAsyncThunk<
  UserUpdateFormSetup,
  void,
  { rejectValue: string | ErrorResponse }
>("admin/getUserInfoFormSetup", async (_, { rejectWithValue }) => {
  try {
    const response = await axiosWithAuth.get(
      `${process.env.REACT_APP_API_URL}/admin/user/setup`
    );
    return response.data;
  } catch (error: unknown) {
    if (error instanceof AxiosError) {
      switch (error.response?.status) {
        case 401:
          return rejectWithValue("Unauthorized");
        case 403:
          return rejectWithValue("Forbidden");
        case 404:
          return rejectWithValue(error.response?.data);
        case 423:
          return rejectWithValue(error.response?.data);
        case 500:
          return rejectWithValue("Internal server error");
        default:
          return rejectWithValue(
            "Error getting user form setup data for admin"
          );
      }
    } else {
      return rejectWithValue("Error getting user form setup data for admin");
    }
  }
});
