/** @jsxImportSource @emotion/react */
import React, { ReactNode, useEffect, useRef, useState } from "react";
import tw from "twin.macro";
import { useAppDispatch, useAppSelector } from "../../../../../app/hooks";
import Loading from "../../../../../components/atoms/Loading";
import TextButton from "../../../../../components/atoms/TextButton";
import ConfirmationDialog from "../../../../../components/molecules/ConfirmationDialog";
import { selectCurrentCustomerId } from "../../../../../store/customer.reducer";
import { clearLoadCart } from "../../../../../store/shipping.actions";
import { RequestStatus } from "../../../../../types/types";
import ReleaseLoadConfirmation from "./ReleaseLoadConfirmation";
import ReviewLoadForm from "./ReviewLoadForm";
import ReviewLoadSite from "./ReviewLoadSite";
import { clearLoadErrors } from "../../../../../store/shipping.reducer";
import { isErrorResponse } from "../../../../../types/predicates";

type Props = {
  children?: ReactNode;
  onCancel: () => void;
};

const ReviewLoad: React.FC<Props> = ({ onCancel }) => {
  const dispatch = useAppDispatch();
  const loadCart = useAppSelector((state) => state.shipping.loads.loadCart);
  const status = useAppSelector((state) => state.shipping.loads.status);
  const [loadStatus, setLoadStatus] = useState<RequestStatus>("idle");
  const customerId = useAppSelector(selectCurrentCustomerId);
  const [showClearModal, setShowClearModal] = useState(false);
  const { error } = useAppSelector((state) => state.shipping.loads);

  const initialRender = useRef(true);

  const clearLoadHandler = () => {
    if (!customerId) return;
    setLoadStatus("pending");
    setShowClearModal(false);
    dispatch(clearLoadCart(customerId))
      .unwrap()
      .then(() => {
        setLoadStatus("success");
        onCancel();
      })
      .catch(() => setLoadStatus("error"));
  };

  useEffect(() => {
    if (!customerId) return;
    if (initialRender.current) {
      initialRender.current = false;
    } else {
      onCancel();
    }
    return () => {
      dispatch(clearLoadErrors());
    };
  }, [customerId]);

  const loadCartErrors = loadCart?.errors ?? [];
  const apiErrors = error
    ? isErrorResponse(error)
      ? error.validations.map((val) => val.message)
      : [error]
    : [];
  const combinedErrors = [...loadCartErrors, ...apiErrors].reduce(
    (acc: string[], curr: string) => {
      if (!acc.includes(curr)) {
        return [...acc, curr];
      } else {
        return acc;
      }
    },
    []
  );

  if (loadCart?.loadReleaseSubmitted) {
    return <ReleaseLoadConfirmation loadCart={loadCart} onCancel={onCancel} />;
  }

  if (loadCart?.lines?.length === 0) {
    return (
      <>
        <div css={tw`text-white font-bold bg-nucor-green text-xs flex justify-between py-1 px-2`}>
          <span className="uppercase w-48">Review / Release Load</span>
          <TextButton onClick={onCancel} css={tw`text-white`}>
            Back to List
          </TextButton>
          <span className="w-48"></span>
        </div>
        <p css={tw`text-xs ml-2 mt-2`}>
          There are no items in the shipping cart.
        </p>
      </>
    );
  }

  return (
    <div css={tw`text-xs relative`}>
      {loadStatus === "pending" && <Loading />}
      {showClearModal && (
        <ConfirmationDialog
          onConfirm={clearLoadHandler}
          onCancel={() => setShowClearModal(false)}
          title="Clear load?"
        >
          Are you sure you want to clear this shipment load?
        </ConfirmationDialog>
      )}
      <div css={tw`w-full text-right mb-1`}>
        <TextButton onClick={() => setShowClearModal(true)}>
          Clear Load
        </TextButton>
      </div>
      <div css={tw`text-white font-bold bg-nucor-green text-xs flex justify-between py-1 px-2`}>
        <span className="uppercase w-48">Review / Release Load</span>
        <TextButton onClick={onCancel} css={tw`text-white`}>
          Back to List
         </TextButton>
        <span className="w-48"></span>
      </div>
      <ul css={tw`list-disc ml-8 my-2`}>
        {loadCart?.warnings.map((warning, index) => (
          <li css={tw`text-nucor-green`} key={index}>
            {warning}
          </li>
        ))}
      </ul>
      {loadCart && !loadCart.valid && (
        <>
          <p css={tw`my-2`}>
            Please resolve the following before submitting the shipping cart:
          </p>
          <ul css={tw`list-disc ml-8 my-2`}>
            {combinedErrors.map((error, index) => (
              <li css={tw`text-red-600`} key={index}>
                {error}
              </li>
            ))}
          </ul>
        </>
      )}
      <div css={tw`relative`}>
        {status === "pending" && <Loading />}
        <ReviewLoadForm>
          {loadCart?.shipToLoads.map((item) => (
            <ReviewLoadSite
              onCancel={onCancel}
              shipToLoad={item}
              key={item.shipTo.id}
            />
          ))}
        </ReviewLoadForm>
      </div>
    </div>
  );
};

export default ReviewLoad;
