/** @jsxImportSource @emotion/react */
import React, { ReactNode, useContext, useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../../../../app/hooks";
import ButtonSelect from "../../../../components/atoms/ButtonSelect";
import Select from "../../../../components/atoms/Select";
import TabGroupSubGroup from "../../../../components/atoms/TabGroupSubGroup";
import {
  selectCurrentCustomerId,
  selectCurrentCustomerShipTos,
} from "../../../../store/customer.reducer";
import {
  selectReleaseOrdersSelectionData,
  updateReleaseOrderSearchCriteria,
} from "../../../../store/shipping.reducer";
import { fetchReleaseOrdersTotals } from "../../../../store/shipping.actions";
import { selectDefaultMill } from "../../../../store/user.reducer";
import { PDFContext } from "./ReleaseOrders";

type ReleaseOrderBrowseCriteria = {
  shipToCustomerId?: string;
  sites?: string[];
  dateRangeType?: string;
  siteKey?: string;
};

type Props = {
  children?: ReactNode;
};

const ReleaseOrdersBrowse: React.FC<Props> = () => {
  const dispatch = useAppDispatch();
  const ctx = useContext(PDFContext);
  const customerId = useAppSelector(selectCurrentCustomerId);
  const defaultMill = useAppSelector(selectDefaultMill);
  const selectionData = useAppSelector(selectReleaseOrdersSelectionData);
  const { searchCriteria } = useAppSelector(
    (state) => state.shipping.releaseOrder
  );

  const shipToCustomerId = searchCriteria.shipToCustomerId ?? "all";
  const sites = searchCriteria.siteKey ?? defaultMill ?? "ALL";
  const dateRangeType = searchCriteria.dateRangeType ?? "all";
  const shipTos = useAppSelector(selectCurrentCustomerShipTos);

  const criteria = {
    ...searchCriteria,
    shipToCustomerId: shipToCustomerId === "all" ? undefined : shipToCustomerId,
    dateRangeType: dateRangeType === "all" ? undefined : dateRangeType,
    sites:
      sites === "ALL"
        ? undefined
        : selectionData?.sites.find((item) => item.key === sites)?.sites,
  };

  useEffect(() => {
    if (!customerId) return;
    // Close pdf if open
    if (ctx?.file) {
      ctx?.setFile(null);
    }
    dispatch(
      fetchReleaseOrdersTotals({ customerId, criteria, requestType: "browse" })
    );
  }, [customerId, searchCriteria]);

  const onSelectionChanged = (criteria: ReleaseOrderBrowseCriteria) => {
    if (!customerId) return;
    dispatch(updateReleaseOrderSearchCriteria(criteria));
  };

  return (
    <>
      <TabGroupSubGroup title="By Ship To">
        <Select
          minWidth="90%"
          maxWidth="100%"
          name="byShipTo"
          data={shipTos}
          onChange={(value: string) => {
            onSelectionChanged({
              ...criteria,
              shipToCustomerId: value === "all" ? undefined : value,
            });
          }}
          value={shipToCustomerId}
        />
      </TabGroupSubGroup>
      <TabGroupSubGroup title="By Mill">
        <ButtonSelect
          isActive={sites === "ALL"}
          onClick={() => {
            onSelectionChanged({
              ...criteria,
              siteKey: "ALL",
            });
          }}
        >
          All
        </ButtonSelect>
        {selectionData?.sites.map((site, index) => {
          return (
            <ButtonSelect
              isActive={sites === site.key}
              onClick={() => {
                onSelectionChanged({
                  ...criteria,
                  siteKey: site.key,
                });
              }}
              key={index}
            >
              {site.description}
            </ButtonSelect>
          );
        })}
      </TabGroupSubGroup>
      <TabGroupSubGroup title="By Due Date">
        {selectionData?.dateSelections.map((date, index) => {
          return (
            <ButtonSelect
              key={index}
              onClick={() => {
                onSelectionChanged({
                  ...criteria,
                  dateRangeType: date.id === "all" ? undefined : date.id,
                });
              }}
              isActive={dateRangeType === date.id}
            >
              {date.description}
            </ButtonSelect>
          );
        })}
      </TabGroupSubGroup>
    </>
  );
};

export default ReleaseOrdersBrowse;
