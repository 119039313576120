/** @jsxImportSource @emotion/react */
import React, { ReactNode, useContext } from "react";
import tw from "twin.macro";
import { useAppDispatch, useAppSelector } from "../../../../app/hooks";
import Loading from "../../../../components/atoms/Loading";
import Pagination from "../../../../components/molecules/PaginationComponent";
import CertsResults from "./CertsResults";
import { updateTestCertPagination } from "../../../../store/document.reducer";
import PDFEmbed from "../../../../components/molecules/PDFEmbed";
import { PDFContext } from "./CertsList";
import TextButton from "../../../../components/atoms/TextButton";

type Props = {
  children?: ReactNode;
};

const CertsContent: React.FC<Props> = () => {
  const dispatch = useAppDispatch();
  const { status } = useAppSelector((state) => state.document.testCert);
  const testCerts = useAppSelector(
    (state) => state.document.testCert.testCertResults
  );
  const { searchCriteria } = useAppSelector((state) => state.document.testCert);
  const ctx = useContext(PDFContext);

  return (
    <div>
      <div css={tw`uppercase text-white font-bold bg-nucor-green text-xs flex justify-between py-1 px-2`}>
        <span css={tw`uppercase w-40`}>Test Certificates</span>
        {ctx?.file && (
          <TextButton onClick={() => ctx?.setFile(null)} css={tw`text-white`}>
            Back to List
          </TextButton>
        )}
        <span className="w-40"></span>
      </div>
      <div css={[tw`relative`, ctx?.file && tw`hidden`]}>
        {status === "pending" && <Loading />}
        <div
          css={tw`bg-gradient-to-b from-[#e6e6e6] to-[#fdfdfd] px-2 py-1 mt-2`}
        >
          <Pagination
            currentPage={searchCriteria.page}
            isLoading={status === "pending"}
            items={testCerts?.count ?? 0}
            onChange={(value) => {
              dispatch(updateTestCertPagination(value));
            }}
          />
        </div>
        <CertsResults testCerts={testCerts} />
      </div>
      {ctx?.file && (
        <PDFEmbed
          onCancel={() => ctx?.setFile(null)}
          file={ctx?.file}
          setFile={ctx?.setFile}
        />
      )}
    </div>
  );
};

export default CertsContent;
