/** @jsxImportSource @emotion/react */
import React, { ReactNode, useState } from "react";
import tw from "twin.macro";
import truck from "../../../../../assets/images/icon_truck.png";
import barge from "../../../../../assets/images/icon_barge.png";
import rail from "../../../../../assets/images/icon_rail.png";
import DatePicker from "../../../../../components/atoms/DatePicker";
import LoadCartData from "./LoadCartData";
import Input from "../../../../../components/atoms/Input";
import TextArea from "../../../../../components/atoms/TextArea";
import { useAppDispatch, useAppSelector } from "../../../../../app/hooks";
import {
  submitLoadCart,
  updateLoadCart,
} from "../../../../../store/shipping.actions";
import { selectCurrentCustomerId } from "../../../../../store/customer.reducer";
import Button from "../../../../../components/atoms/Button";
import { dateToString, stringToDate } from "../../../../../helpers/dateHelpers";
import observerable from "../observer";
import { stringToBase64 } from "../../../../../helpers/stringToBase64";

type Props = {
  children?: ReactNode;
  currentDeliveryMode?: string;
};

const ReviewLoadForm: React.FC<Props> = ({ currentDeliveryMode, children }) => {
  const loadCart = useAppSelector((state) => state.shipping.loads.loadCart);
  const dispatch = useAppDispatch();
  const customerId = useAppSelector(selectCurrentCustomerId);
  const status = useAppSelector((state) => state.shipping.loads.status);
  let transportIcon: string | undefined;
  const [ntpNotifyCarrier, setNtpNotifyCarrier] = useState(
    loadCart?.ntpNotifyCarrier
  );
  const [probill, setProbill] = useState(loadCart?.proBillNumber ?? "");
  const [notes, setNotes] = useState(loadCart?.notes ?? "");
  const [shipDate, setShipDate] = useState<Date | undefined>(
    stringToDate(loadCart?.requestedShippingDate)
  );

  const formIsValid = shipDate !== undefined && ntpNotifyCarrier !== undefined;

  const minDate = stringToDate(loadCart?.minDate);
  const maxDate = stringToDate(loadCart?.maxDate);

  switch (currentDeliveryMode) {
    case "Truck":
      transportIcon = truck;
      break;
    case "Rail":
      transportIcon = rail;
      break;
    case "Barge":
      transportIcon = barge;
      break;
    default:
      transportIcon = undefined;
  }

  const submitHandler = async () => {
    if (!customerId) return;
    try {
      const data = {
        requestedShippingDate: dateToString(shipDate),
        proBillNumber: probill,
        ntpNotifyCarrier: ntpNotifyCarrier,
        encodedNotes: stringToBase64(notes),
      };
      const result = await dispatch(
        submitLoadCart({ customerId, body: data })
      ).unwrap();
      if (result.loadReleaseSubmitted) {
        observerable.notify();
      }
    } catch (err) {
      console.log(err);
    }
  };

  const updateHandler = () => {
    if (!customerId) return;
    const data = {
      requestedShippingDate: dateToString(shipDate),
      proBillNumber: probill,
      ntpNotifyCarrier: ntpNotifyCarrier,
      encodedNotes: stringToBase64(notes),
    };
    dispatch(updateLoadCart({ customerId, body: data }));
  };

  return (
    <form>
      <div
        css={tw`h-[80px] bg-gradient-to-b from-nucor-gradient-outer via-nucor-gradient-inner to-nucor-gradient-outer flex justify-end p-2`}
      >
        <div css={tw`flex-1 w-full`}>
          <fieldset css={tw`flex items-center gap-4`}>
            <label css={tw`text-nucor-gray`}>
              Requested Ship Date: <span css={tw`text-red-600`}>*</span>
            </label>
            <DatePicker
              highlight
              name="shipDate"
              value={shipDate}
              onChange={setShipDate}
              minDate={minDate}
              maxDate={maxDate}
              hideWeekends
            />
          </fieldset>
          <img css={tw`h-5 mx-auto`} src={transportIcon} />
        </div>
        <div css={tw`flex flex-col`}>
          <fieldset css={tw`flex flex-1 justify-end items-start`}>
            <p css={tw`text-nucor-gray font-bold mr-4`}>
              Who will notify the carrier? <span css={tw`text-red-600`}>*</span>
            </p>
            <input
              css={tw`mx-1`}
              type="checkbox"
              name="ntp"
              checked={ntpNotifyCarrier === true}
              onChange={() => setNtpNotifyCarrier(true)}
            />
            <label css={tw`text-nucor-gray font-bold`} htmlFor="ntp">
              NTP
            </label>
            <input
              css={tw`mx-1 ml-4`}
              type="checkbox"
              name="customer"
              checked={ntpNotifyCarrier === false}
              onChange={() => setNtpNotifyCarrier(false)}
            />
            <label css={tw`text-nucor-gray font-bold`} htmlFor="customer">
              Customer
            </label>
          </fieldset>
          <LoadCartData />
        </div>
      </div>
      <div css={tw`bg-[#eee] p-2 flex`}>
        <div css={tw`flex-1`} />
        <div css={tw`w-[500px]`}>
          <fieldset css={tw`grid grid-cols-12 mb-2`}>
            <label
              css={tw`text-nucor-gray mr-2 col-span-3`}
              htmlFor="releaseProBill"
            >
              Release/Pro Bill:
            </label>
            <Input
              css={tw`col-span-4`}
              name="releaseProBill"
              value={probill}
              onChange={(e) => setProbill(e.target.value)}
            />
          </fieldset>
          <fieldset css={tw`grid grid-cols-12`}>
            <label css={tw`text-nucor-gray mr-2 col-span-3`} htmlFor="notes">
              Notes:
            </label>
            <TextArea
              css={tw`w-full col-span-9`}
              name="notes"
              value={notes}
              onChange={(e) => setNotes(e.target.value)}
            />
          </fieldset>
        </div>
      </div>
      {children}
      <div css={tw`bg-[#eee] p-2 text-right`}>
        <Button
          disabled={status === "pending"}
          css={tw`mx-4`}
          onClick={updateHandler}
        >
          Save Shipment
        </Button>
        <Button
          css={[
            tw`bg-red-700`,
            (!formIsValid || status === "pending") &&
              tw`bg-gray-300 text-gray-800`,
          ]}
          disabled={!formIsValid || status === "pending"}
          onClick={submitHandler}
        >
          Release Shipment
        </Button>
      </div>
      <p css={tw`w-full text-left bg-[#eee] pl-2 pb-2 italic`}>
        <span css={tw`text-red-600`}>*</span> indicates required field
      </p>
    </form>
  );
};

export default ReviewLoadForm;
