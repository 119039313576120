import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { PaginationValues } from "../components/molecules/PaginationComponent";
import {
  PackingSlipSetup,
  InvoiceDocumentSetup,
  OrdersDocumentSetup,
  PackingSlipPageResult,
  TestCertsSetup,
  InvoiceDocumentPagedResult,
  TestCertPagedResult,
  ClaimsDocumentsSetup,
  ClaimsSearchSetup,
  OrderSummaryPagedResult,
  OpenLoadsSetup,
  LoadSummary,
  SearchCriteria,
  RequestStatus,
  QuoteSelectionData,
  QuoteSummaryPagedResult,
  QuickQuoteSummaryResult,
  QuickQuoteSelectionData,
  ClaimsPagedResults,
  POSearchSelectionData,
  SearchDocument,
  ErrorResponse,
  ClaimDetailResults,
  QuickQuoteView,
  QuickQuoteSetup,
  QuickQuoteLineDetail,
  QQInventoryList,
  QQRolling,
} from "../types/types";
import { setCurrentCustomerDetails } from "./customer.actions";
import {
  fetchPackingSlipSetup,
  fetchInvoiceDocumentsSetup,
  fetchOrderDocumentsSetup,
  fetchTestCertDocumentsSetup,
  searchPackingSlips,
  searchOrderDocumentOrder,
  searchInvoiceDocuments,
  searchTestCertDocument,
  fetchClaimsDocumentsSetup,
  fetchClaimsSearchSetup,
  fetchClaimDetail,
  fetchClaims,
  fetchClaimsExcel,
  fetchOpenLoadsSetup,
  searchOpenLoads,
  fetchQuoteExcel,
  fetchQuoteSelectionData,
  fetchQuickQuoteSelectionData,
  fetchQuoteResults,
  fetchQuickQuoteResults,
  fetchQuickQuote,
  fetchQuickQuoteSetup,
  fetchQuotePDF,
  fetchPOSearchSelectionData,
  fetchPOSearchResults,
  fetchDocument,
  addQuickQuote,
  updateQuickQuote,
  updateQuickQuoteLine,
  updateQuickQuoteLineDetail,
  updateQuickQuotePrices,
  updateQuickQuoteDueDates,
  addQuickQuoteLine,
  deleteQuickQuoteLine,
  fetchQuickQuoteLineDetail,
  fetchQuickQuoteItemPricing,
} from "./document.actions";
import { RootState } from "./store";

type InitialState = {
  loading: boolean;
  error?: string;
  orders: {
    ordersDocumentsSelectionData?: OrdersDocumentSetup;
    ordersResults?: OrderSummaryPagedResult;
    status: RequestStatus;
    searchCriteria: SearchCriteria;
    error?: string | ErrorResponse;
  };
  packingSlip: {
    packingSlipSelectionData?: PackingSlipSetup;
    packingSlipResults?: PackingSlipPageResult;
    status: RequestStatus;
    searchCriteria: SearchCriteria;
    error?: string | ErrorResponse;
  };
  invoices: {
    invoiceDocumentsSelectionData?: InvoiceDocumentSetup;
    invoiceResults?: InvoiceDocumentPagedResult;
    status: RequestStatus;
    searchCriteria: SearchCriteria;
    error?: string | ErrorResponse;
  };
  testCert: {
    testCertDocumentsSelectionData?: TestCertsSetup;
    testCertResults?: TestCertPagedResult;
    status: RequestStatus;
    searchCriteria: SearchCriteria;
    error?: string | ErrorResponse;
  };
  claims: {
    claimsSelectionData?: ClaimsDocumentsSetup;
    claimDetailResults?: ClaimDetailResults;
    status: RequestStatus;
    claimsSearchSelectionData?: ClaimsSearchSetup;
    claimsPagedResults?: ClaimsPagedResults;
    searchCriteria: SearchCriteria;
    error?: string | ErrorResponse;
  };
  openLoads: {
    openLoadsSelectionData?: OpenLoadsSetup;
    openLoadsResults?: LoadSummary[];
    status: RequestStatus;
    searchCriteria: SearchCriteria;
    error?: string | ErrorResponse;
  };
  quote: {
    quoteSelectionData?: QuoteSelectionData;
    quoteResults?: QuoteSummaryPagedResult;
    status: RequestStatus;
    searchCriteria: SearchCriteria;
    error?: string | ErrorResponse;
  };
  quickquote: {
    quoteSelectionData?: QuickQuoteSelectionData;
    quoteResults?: QuickQuoteSummaryResult;
    status: RequestStatus;
    pricingStatus: RequestStatus;
    searchCriteria: SearchCriteria;
    error?: string | ErrorResponse;
    selectedQuoteId: number;
    quickQuoteView?: QuickQuoteView;
    quickQuoteSetup?: QuickQuoteSetup;
    quickQuoteLineDetail?: QuickQuoteLineDetail;
  };
  poSearch: {
    selectionData?: POSearchSelectionData;
    searchResults?: SearchDocument[];
    status: RequestStatus;
  };
  docSearch: {
    status: RequestStatus;
  };
};

const initialState: InitialState = {
  loading: false,
  error: "",
  orders: {
    status: "idle",
    searchCriteria: {
      dir: "desc",
      sort: "orderDate",
    },
  },
  packingSlip: {
    status: "idle",
    searchCriteria: {
      dir: "desc",
      sort: "shipDate",
    },
  },
  invoices: {
    status: "idle",
    searchCriteria: {
      sort: "invoiceDate",
      dir: "desc",
    },
  },
  testCert: {
    status: "idle",
    searchCriteria: {
      sort: "mtrCreatedDate",
      dir: "desc",
    },
  },
  claims: {
    status: "idle",
    searchCriteria: {
      sort: "quoteNumber",
      dir: "desc",
    },
  },
  openLoads: {
    status: "idle",
    searchCriteria: {
      sort: "loadId",
      dir: "asc",
    },
  },
  quote: {
    status: "idle",
    searchCriteria: {
      sort: "quoteNumber",
      dir: "desc",
    },
  },
  quickquote: {
    status: "idle",
    pricingStatus: "idle",
    searchCriteria: {
      sort: "id",
      dir: "desc",
    },
    selectedQuoteId: 0,
  },
  poSearch: {
    status: "idle",
  },
  docSearch: {
    status: "idle",
  },
};

const documentSlice = createSlice({
  name: "document",
  initialState,
  reducers: {
    resetInvoiceSearchCriteria: (state) => {
      state.invoices.searchCriteria = {
        ...state.invoices.searchCriteria,
        page: 1,
      };
      state.invoices.invoiceResults = undefined;
      state.invoices.invoiceDocumentsSelectionData = undefined;
    },
    resetQuoteSearchCriteria: (state) => {
      state.quote.searchCriteria = {
        ...state.quote.searchCriteria,
        page: 1,
      };
      state.quote.quoteResults = undefined;
      state.quote.quoteSelectionData = undefined;
    },
    resetQuickQuoteSearchCriteria: (state) => {
      state.quote.searchCriteria = {
        ...state.quote.searchCriteria,
        page: 1,
      };
      state.quote.quoteResults = undefined;
      state.quote.quoteSelectionData = undefined;
    },
    resetOpenLoadsSearchCriteria: (state) => {
      state.openLoads.searchCriteria = {
        ...state.openLoads.searchCriteria,
        page: 1,
      };
      state.openLoads.openLoadsSelectionData = undefined;
      state.openLoads.openLoadsResults = undefined;
    },
    resetOrdersSearchCriteria: (state) => {
      state.orders.searchCriteria = {
        ...state.orders.searchCriteria,
        page: 1,
      };
      state.orders.ordersDocumentsSelectionData = undefined;
      state.orders.ordersResults = undefined;
    },
    resetTestCertSearchCriteria: (state) => {
      state.testCert.searchCriteria = {
        ...state.testCert.searchCriteria,
        page: 1,
      };
      state.testCert.testCertDocumentsSelectionData = undefined;
      state.testCert.testCertResults = undefined;
    },
    resetPackingSlipSearchCriteria: (state) => {
      state.packingSlip.searchCriteria = {
        ...state.packingSlip.searchCriteria,
        page: 1,
      };
      state.packingSlip.packingSlipSelectionData = undefined;
      state.packingSlip.packingSlipResults = undefined;
    },
    updateInvoiceSearchCriteria: (
      state,
      action: PayloadAction<SearchCriteria>
    ) => {
      state.invoices.searchCriteria = {
        ...state.invoices.searchCriteria,
        ...action.payload,
        page: 1,
      };
    },
    updateInvoicePagination: (
      state,
      action: PayloadAction<PaginationValues>
    ) => {
      state.invoices.searchCriteria = {
        ...state.invoices.searchCriteria,
        ...action.payload,
      };
    },
    updateClaimsPagination: (
      state,
      action: PayloadAction<PaginationValues>
    ) => {
      state.claims.searchCriteria = {
        ...state.claims.searchCriteria,
        ...action.payload,
      };
    },
    updateQuickQuotePagination: (
      state,
      action: PayloadAction<PaginationValues>
    ) => {
      state.quickquote.searchCriteria = {
        ...state.quickquote.searchCriteria,
        ...action.payload,
      };
    },
    invoiceSort: (state, action: PayloadAction<string>) => {
      const direction =
        state.invoices.searchCriteria?.sort !== action.payload
          ? "desc"
          : state.invoices.searchCriteria?.dir;
      state.invoices.searchCriteria = {
        ...state.invoices.searchCriteria,
        sort: action.payload,
        dir: direction === "asc" ? "desc" : "asc",
      };
    },
    updateOrderSearchCriteria: (
      state,
      action: PayloadAction<SearchCriteria>
    ) => {
      state.orders.searchCriteria = {
        ...state.orders.searchCriteria,
        ...action.payload,
      };
    },
    updateOrdersPagination: (
      state,
      action: PayloadAction<PaginationValues>
    ) => {
      state.orders.searchCriteria = {
        ...state.orders.searchCriteria,
        ...action.payload,
      };
    },
    ordersSort: (state, action: PayloadAction<string>) => {
      const currentSort = state.orders.searchCriteria.sort;
      const currentDir = state.orders.searchCriteria.dir;
      state.orders.searchCriteria = {
        ...state.orders.searchCriteria,
        sort: action.payload,
        dir:
          currentSort !== action.payload
            ? "desc"
            : currentDir === "asc"
            ? "desc"
            : "asc",
      };
    },
    openLoadsSort: (state, action: PayloadAction<string>) => {
      const currentSort = state.openLoads.searchCriteria.sort;
      const currentDir = state.openLoads.searchCriteria.dir;
      state.openLoads.searchCriteria = {
        ...state.openLoads.searchCriteria,
        sort: action.payload,
        dir:
          currentSort !== action.payload
            ? "desc"
            : currentDir === "asc"
            ? "desc"
            : "asc",
      };
    },
    quoteSort: (state, action: PayloadAction<string>) => {
      const currentSort = state.quote.searchCriteria.sort;
      const currentDir = state.quote.searchCriteria.dir;
      state.quote.searchCriteria = {
        ...state.quote.searchCriteria,
        sort: action.payload,
        dir:
          currentSort !== action.payload
            ? "desc"
            : currentDir === "asc"
            ? "desc"
            : "asc",
      };
    },
    quickQuoteSort: (state, action: PayloadAction<string>) => {
      const currentSort = state.quickquote.searchCriteria.sort;
      const currentDir = state.quickquote.searchCriteria.dir;
      state.quickquote.searchCriteria = {
        ...state.quickquote.searchCriteria,
        sort: action.payload,
        dir:
          currentSort !== action.payload
            ? "desc"
            : currentDir === "asc"
            ? "desc"
            : "asc",
      };
    },
    updateSelectedQuoteId: (state, action: PayloadAction<number>) => {
      state.quickquote.selectedQuoteId = action.payload;
    },
    updateQuickQuoteSearchCriteria: (
      state,
      action: PayloadAction<SearchCriteria>
    ) => {
      state.quickquote.searchCriteria = {
        ...state.quickquote.searchCriteria,
        ...action.payload,
        page: 1,
      };
    },
    updateQuoteSearchCriteria: (
      state,
      action: PayloadAction<SearchCriteria>
    ) => {
      state.quote.searchCriteria = {
        ...state.quote.searchCriteria,
        ...action.payload,
        page: 1,
      };
    },
    updateQuotePagination: (state, action: PayloadAction<PaginationValues>) => {
      state.quote.searchCriteria = {
        ...state.quote.searchCriteria,
        ...action.payload,
      };
    },
    clearQuoteSearchCriteria: (state) => {
      state.quote.searchCriteria = {
        ...state.quote.searchCriteria,
        myCustomers: false,
        quoteNumber: undefined,
        dueDate: undefined,
        length: undefined,
        grade: undefined,
        shape: undefined,
        height: undefined,
        width: undefined,
        decimalWallThickness: undefined,
        dateRangeType: undefined,
        quoteStatus: undefined,
      };
    },
    clearQuickQuoteSearchCriteria: (state) => {
      state.quote.searchCriteria = {
        ...state.quote.searchCriteria,
        myCustomers: false,
        quoteNumber: undefined,
        dueDate: undefined,
        length: undefined,
        grade: undefined,
        shape: undefined,
        height: undefined,
        width: undefined,
        decimalWallThickness: undefined,
        dateRangeType: undefined,
        quoteStatus: undefined,
      };
    },

    packingSlipSort: (state, action: PayloadAction<string>) => {
      const currentSort = state.packingSlip.searchCriteria.sort;
      const currentDir = state.packingSlip.searchCriteria.dir;
      state.packingSlip.searchCriteria = {
        ...state.packingSlip.searchCriteria,

        sort: action.payload,
        dir:
          currentSort !== action.payload
            ? "desc"
            : currentDir === "asc"
            ? "desc"
            : "asc",
      };
    },
    updatePackingSlipPagination: (
      state,
      action: PayloadAction<PaginationValues>
    ) => {
      state.packingSlip.searchCriteria = {
        ...state.packingSlip.searchCriteria,
        ...action.payload,
      };
    },
    updatePackingSlipSearchCriteria: (
      state,
      action: PayloadAction<SearchCriteria>
    ) => {
      state.packingSlip.searchCriteria = {
        ...state.packingSlip.searchCriteria,
        ...action.payload,
      };
    },
    clearPackingSlipSearchCriteria: (state) => {
      state.packingSlip.searchCriteria = {
        ...state.packingSlip.searchCriteria,
        customerPartNumber: undefined,
        customerPONumber: undefined,
        salesOrderNumber: undefined,
        packingSlipNumber: undefined,
        proBill: undefined,
        length: undefined,
        grade: undefined,
        shape: undefined,
        height: undefined,
        width: undefined,
        decimalWallThickness: undefined,
      };
    },

    updateOpenLoadsSearchCriteria: (
      state,
      action: PayloadAction<SearchCriteria>
    ) => {
      state.openLoads.searchCriteria = {
        ...state.openLoads.searchCriteria,
        ...action.payload,
      };
    },
    clearOpenLoadsSearchCriteria: (state) => {
      state.openLoads.searchCriteria = {
        ...state.openLoads.searchCriteria,
        myCustomers: false,
        loadId: undefined,
        customerPONumber: undefined,
        salesOrderNumber: undefined,
        customerPartNumber: undefined,
        proBill: undefined,
        length: undefined,
        grade: undefined,
        shape: undefined,
        height: undefined,
        width: undefined,
        decimalWallThickness: undefined,
      };
    },

    testCertSort: (state, action: PayloadAction<string>) => {
      const currentSort = state.testCert.searchCriteria.sort;
      const currentDir = state.testCert.searchCriteria.dir;
      state.testCert.searchCriteria = {
        ...state.testCert.searchCriteria,
        sort: action.payload,
        dir:
          currentSort !== action.payload
            ? "desc"
            : currentDir === "asc"
            ? "desc"
            : "asc",
      };
    },
    updateTestCertSearchCriteria: (
      state,
      action: PayloadAction<SearchCriteria>
    ) => {
      state.testCert.searchCriteria = {
        ...state.testCert.searchCriteria,
        ...action.payload,
        page: 1,
      };
    },
    updateTestCertPagination: (
      state,
      action: PayloadAction<PaginationValues>
    ) => {
      state.testCert.searchCriteria = {
        ...state.testCert.searchCriteria,
        ...action.payload,
      };
    },
    clearTestCertSearchCriteria: (state) => {
      state.testCert.searchCriteria = {
        ...state.testCert.searchCriteria,
        customerPONumber: undefined,
        fromDate: undefined,
        toDate: undefined,
        salesOrderNumber: undefined,
        heat: undefined,
        length: undefined,
        grade: undefined,
        shape: undefined,
        height: undefined,
        width: undefined,
        decimalWallThickness: undefined,
      };
    },
    clearQuickQuoteView: (state) => {
      state.quickquote.quickQuoteSetup = undefined;
    },
    clearQuickQuoteLineDetail: (state) => {
      state.quickquote.quickQuoteLineDetail = undefined;
    },
    clearPoSearchResults: (state) => {
      state.poSearch.searchResults = undefined;
    },
    clearDocumentErrors: (state) => {
      state.testCert.error = undefined;
      state.packingSlip.error = undefined;
      state.invoices.error = undefined;
      state.orders.error = undefined;
      state.quote.error = undefined;
      state.openLoads.error = undefined;
      state.quickquote.error = undefined;
    },
    updateQuickQuoteLineNote: (
      state,
      action: PayloadAction<string>
    ) => {
      const { quickQuoteLineDetail } = state.quickquote;

      if (quickQuoteLineDetail) {
        quickQuoteLineDetail.notes = action.payload;
      }
    },
    updateQuickQuoteLineStock: (
      state,
      action: PayloadAction<QQInventoryList>
    ) => {
      const { quickQuoteLineDetail } = state.quickquote;
      if (quickQuoteLineDetail && quickQuoteLineDetail.stockSelections) {
        quickQuoteLineDetail.stockSelections.forEach((item, index) => {
          if (
            item.inventoryItem.ordinal === action.payload.inventoryItem.ordinal
          ) {
            quickQuoteLineDetail.stockSelections[index] = {
              ...item,
              selected: action.payload.selected,
            };
          }
        });
      }
    },

    updateQuickQuoteLineRolling: (state, action: PayloadAction<QQRolling>) => {
      const { quickQuoteLineDetail } = state.quickquote;
      if (quickQuoteLineDetail && quickQuoteLineDetail.rollingSelections) {
        quickQuoteLineDetail.rollingSelections.forEach((resourceRolling) => {
          resourceRolling.rollings.forEach((item) => {
            if (
              item.rolling.rollingCycleId ===
                action.payload.rolling.rollingCycleId &&
              item.rolling.cycleLinePerSequence ===
                action.payload.rolling.cycleLinePerSequence
            ) {
              item.selected = action.payload.selected;
            }
          });
        });
      }
    },
  },
  extraReducers: (builder) => {
    builder.addCase(setCurrentCustomerDetails.fulfilled, (state) => {
      state.packingSlip.searchCriteria =
        initialState.packingSlip.searchCriteria;
      state.invoices.searchCriteria = initialState.invoices.searchCriteria;
    });
    builder.addCase(fetchOrderDocumentsSetup.fulfilled, (state, action) => {
      state.orders.ordersDocumentsSelectionData = action.payload;
    });
    builder.addCase(fetchInvoiceDocumentsSetup.fulfilled, (state, action) => {
      state.invoices.invoiceDocumentsSelectionData = action.payload;
    });
    builder.addCase(fetchPackingSlipSetup.fulfilled, (state, action) => {
      state.packingSlip.packingSlipSelectionData = action.payload;
    });
    builder.addCase(fetchTestCertDocumentsSetup.fulfilled, (state, action) => {
      state.testCert.testCertDocumentsSelectionData = action.payload;
    });
    builder.addCase(searchOrderDocumentOrder.pending, (state) => {
      state.orders.status = "pending";
      state.orders.error = undefined;
    });
    builder.addCase(searchOrderDocumentOrder.fulfilled, (state, action) => {
      state.orders.status = "success";
      state.orders.ordersResults = action.payload;
    });
    builder.addCase(searchOrderDocumentOrder.rejected, (state, action) => {
      state.orders.status = "error";
      state.orders.ordersResults = undefined;
      state.orders.error = action.payload;
    });
    builder.addCase(searchPackingSlips.pending, (state) => {
      state.packingSlip.status = "pending";
      state.packingSlip.error = undefined;
    });
    builder.addCase(searchPackingSlips.fulfilled, (state, action) => {
      state.packingSlip.status = "success";
      state.packingSlip.packingSlipResults = action.payload;
    });
    builder.addCase(searchPackingSlips.rejected, (state, action) => {
      state.packingSlip.status = "error";
      state.packingSlip.packingSlipResults = undefined;
      state.packingSlip.error = action.payload;
    });
    builder.addCase(searchInvoiceDocuments.pending, (state) => {
      state.invoices.status = "pending";
      state.invoices.error = undefined;
    });
    builder.addCase(searchInvoiceDocuments.fulfilled, (state, action) => {
      state.invoices.status = "success";
      state.invoices.invoiceResults = action.payload;
    });
    builder.addCase(searchInvoiceDocuments.rejected, (state, action) => {
      state.invoices.status = "error";
      state.invoices.invoiceResults = undefined;
      state.invoices.error = action.payload;
    });
    builder.addCase(searchTestCertDocument.pending, (state) => {
      state.testCert.status = "pending";
      state.testCert.error = undefined;
    });
    builder.addCase(searchTestCertDocument.fulfilled, (state, action) => {
      state.testCert.status = "success";
      state.testCert.testCertResults = action.payload;
    });
    builder.addCase(searchTestCertDocument.rejected, (state, action) => {
      state.testCert.status = "error";
      state.testCert.testCertResults = undefined;
      state.testCert.error = action.payload;
    });
    builder.addCase(fetchClaimsDocumentsSetup.pending, (state) => {
      state.claims.status = "pending";
    });
    builder.addCase(fetchClaimsDocumentsSetup.fulfilled, (state, action) => {
      state.claims.status = "success";
      state.claims.claimsSelectionData = action.payload;
    });
    builder.addCase(fetchClaimsDocumentsSetup.rejected, (state) => {
      state.claims.status = "error";
      state.claims.claimsSelectionData = undefined;
    });
    builder.addCase(fetchClaims.pending, (state) => {
      state.claims.status = "pending";
    });
    builder.addCase(fetchClaims.fulfilled, (state, action) => {
      state.claims.status = "success";
      state.claims.claimsPagedResults = action.payload;
    });
    builder.addCase(fetchClaims.rejected, (state) => {
      state.claims.status = "error";
      state.claims.claimsSelectionData = undefined;
    });
    builder.addCase(fetchClaimsExcel.pending, (state) => {
      state.quote.status = "pending";
    });
    builder.addCase(fetchClaimsExcel.fulfilled, (state) => {
      state.quote.status = "success";
    });
    builder.addCase(fetchClaimsExcel.rejected, (state) => {
      state.quote.status = "error";
    });
    builder.addCase(fetchClaimsSearchSetup.pending, (state) => {
      state.claims.status = "pending";
    });
    builder.addCase(fetchClaimsSearchSetup.fulfilled, (state, action) => {
      state.claims.status = "success";
      state.claims.claimsSearchSelectionData = action.payload;
    });
    builder.addCase(fetchClaimsSearchSetup.rejected, (state) => {
      state.claims.status = "error";
      state.claims.claimsSearchSelectionData = undefined;
    });
    builder.addCase(fetchClaimDetail.pending, (state) => {
      state.claims.status = "pending";
    });
    builder.addCase(fetchClaimDetail.fulfilled, (state, action) => {
      state.claims.status = "success";
      state.claims.claimDetailResults = action.payload;
    });
    builder.addCase(fetchClaimDetail.rejected, (state) => {
      state.claims.status = "error";
      state.claims.claimsSelectionData = undefined;
    });
    builder.addCase(fetchOpenLoadsSetup.pending, (state) => {
      state.openLoads.status = "pending";
    });
    builder.addCase(fetchOpenLoadsSetup.fulfilled, (state, action) => {
      state.openLoads.status = "success";
      state.openLoads.openLoadsSelectionData = action.payload;
    });
    builder.addCase(fetchOpenLoadsSetup.rejected, (state) => {
      state.openLoads.status = "error";
      state.openLoads.openLoadsSelectionData = undefined;
    });
    builder.addCase(searchOpenLoads.pending, (state) => {
      state.openLoads.status = "pending";
      state.openLoads.error = undefined;
    });
    builder.addCase(searchOpenLoads.fulfilled, (state, action) => {
      state.openLoads.status = "success";
      state.openLoads.openLoadsResults = action.payload;
    });
    builder.addCase(searchOpenLoads.rejected, (state, action) => {
      state.openLoads.status = "error";
      state.openLoads.openLoadsSelectionData = undefined;
      state.openLoads.error = action.payload;
    });
    builder.addCase(fetchQuoteExcel.pending, (state) => {
      state.quote.status = "pending";
    });
    builder.addCase(fetchQuoteExcel.fulfilled, (state) => {
      state.quote.status = "success";
    });
    builder.addCase(fetchQuoteExcel.rejected, (state) => {
      state.quote.status = "error";
    });
    builder.addCase(fetchQuotePDF.pending, (state) => {
      state.quote.status = "pending";
    });
    builder.addCase(fetchQuotePDF.fulfilled, (state) => {
      state.quote.status = "success";
    });
    builder.addCase(fetchQuotePDF.rejected, (state) => {
      state.quote.status = "error";
    });
    builder.addCase(fetchQuoteSelectionData.pending, (state) => {
      state.quote.status = "pending";
    });
    builder.addCase(fetchQuoteSelectionData.fulfilled, (state, action) => {
      state.quote.status = "success";
      state.quote.quoteSelectionData = action.payload;
    });
    builder.addCase(fetchQuoteSelectionData.rejected, (state) => {
      state.quickquote.status = "error";
      state.quickquote.quoteSelectionData = undefined;
    });
    builder.addCase(fetchQuickQuoteSelectionData.pending, (state) => {
      state.quickquote.status = "pending";
    });
    builder.addCase(fetchQuickQuoteSelectionData.fulfilled, (state, action) => {
      state.quickquote.status = "success";
      state.quickquote.quoteSelectionData = action.payload;
    });
    builder.addCase(fetchQuickQuoteSelectionData.rejected, (state) => {
      state.quickquote.status = "error";
      state.quickquote.quoteSelectionData = undefined;
    });
    builder.addCase(fetchQuoteResults.pending, (state) => {
      state.quote.status = "pending";
    });
    builder.addCase(fetchQuoteResults.fulfilled, (state, action) => {
      state.quote.status = "success";
      state.quote.quoteResults = action.payload;
    });
    builder.addCase(fetchQuoteResults.rejected, (state) => {
      state.quote.status = "error";
      state.quote.quoteResults = undefined;
    });
    builder.addCase(fetchQuickQuoteResults.pending, (state) => {
      state.quickquote.status = "pending";
    });
    builder.addCase(fetchQuickQuoteResults.fulfilled, (state, action) => {
      state.quickquote.status = "success";
      state.quickquote.quoteResults = action.payload;
    });
    builder.addCase(fetchQuickQuoteResults.rejected, (state) => {
      state.quickquote.status = "error";
      state.quickquote.quoteResults = undefined;
    });
    builder.addCase(fetchQuickQuote.pending, (state) => {
      state.quickquote.status = "pending";
    });
    builder.addCase(fetchQuickQuote.fulfilled, (state, action) => {
      state.quickquote.status = "success";
      state.quickquote.quickQuoteView = action.payload;
    });
    builder.addCase(fetchQuickQuote.rejected, (state) => {
      state.quickquote.status = "error";
      state.quickquote.quoteResults = undefined;
    });
    builder.addCase(fetchQuickQuoteLineDetail.pending, (state) => {
      state.quickquote.status = "pending";
      state.quickquote.quickQuoteLineDetail = undefined;
    });
    builder.addCase(fetchQuickQuoteLineDetail.fulfilled, (state, action) => {
      state.quickquote.status = "success";
      state.quickquote.quickQuoteLineDetail = action.payload;
    });
    builder.addCase(fetchQuickQuoteLineDetail.rejected, (state) => {
      state.quickquote.status = "error";
      state.quickquote.quoteResults = undefined;
    });
    builder.addCase(fetchQuickQuoteSetup.pending, (state) => {
      state.quickquote.status = "pending";
    });
    builder.addCase(fetchQuickQuoteSetup.fulfilled, (state, action) => {
      state.quickquote.status = "success";

      const quickQuoteSetup: QuickQuoteSetup = action.payload;
      quickQuoteSetup?.parts?.length === 0 && 
      (quickQuoteSetup.itemTypes = quickQuoteSetup.itemTypes.filter(
        (itemType) => itemType.id !== "Part"
      ));

      state.quickquote.quickQuoteSetup = quickQuoteSetup;
    });
    builder.addCase(fetchQuickQuoteSetup.rejected, (state) => {
      state.quickquote.status = "error";
      state.quickquote.quoteResults = undefined;
    });
    builder.addCase(fetchQuickQuoteItemPricing.pending, (state) => {
      state.quickquote.pricingStatus = "pending";
    });
    builder.addCase(fetchQuickQuoteItemPricing.fulfilled, (state, action) => {
      state.quickquote.pricingStatus = "success";
      if (state.quickquote.quickQuoteSetup) {
        state.quickquote.quickQuoteSetup.itemNumberPriceMap = action.payload.itemNumberPriceMap;
        state.quickquote.quickQuoteSetup.partNumberPriceMap = action.payload.partNumberPriceMap;
      }
    });
    builder.addCase(fetchQuickQuoteItemPricing.rejected, (state) => {
      state.quickquote.pricingStatus = "error";
    });
    builder.addCase(addQuickQuote.pending, (state) => {
      state.quickquote.status = "pending";
    });
    builder.addCase(addQuickQuote.fulfilled, (state, action) => {
      state.quickquote.status = "success";
      if (state.quickquote.quickQuoteSetup) {
        state.quickquote.quickQuoteSetup.quickQuote = action.payload;
      }
    });
    builder.addCase(addQuickQuote.rejected, (state) => {
      state.quickquote.status = "error";
    });
    builder.addCase(updateQuickQuoteLine.pending, (state) => {
      state.quickquote.status = "pending";
    });
    builder.addCase(updateQuickQuoteLine.fulfilled, (state, action) => {
      state.quickquote.status = "success";
      if (state.quickquote.quickQuoteSetup) {
        state.quickquote.quickQuoteSetup.quickQuote = action.payload;
      }
    });
    builder.addCase(updateQuickQuoteLine.rejected, (state, action) => {
      state.quickquote.status = "error";
      state.quickquote.error = action.payload;
    });
    builder.addCase(addQuickQuoteLine.pending, (state) => {
      state.quickquote.status = "pending";
    });
    builder.addCase(addQuickQuoteLine.fulfilled, (state, action) => {
      state.quickquote.status = "success";
      if (state.quickquote.quickQuoteSetup) {
        state.quickquote.quickQuoteSetup.quickQuote = action.payload;
      }
    });
    builder.addCase(addQuickQuoteLine.rejected, (state) => {
      state.quickquote.status = "error";
    });
    builder.addCase(deleteQuickQuoteLine.pending, (state) => {
      state.quickquote.status = "pending";
    });
    builder.addCase(deleteQuickQuoteLine.fulfilled, (state, action) => {
      state.quickquote.status = "success";
      if (state.quickquote.quickQuoteSetup) {
        state.quickquote.quickQuoteSetup.quickQuote = action.payload;
      }
    });
    builder.addCase(deleteQuickQuoteLine.rejected, (state) => {
      state.quickquote.status = "error";
    });
    builder.addCase(updateQuickQuote.pending, (state) => {
      state.quickquote.status = "pending";
    });
    builder.addCase(updateQuickQuote.fulfilled, (state, action) => {
      state.quickquote.status = "success";
      if (state.quickquote.quickQuoteSetup) {
        state.quickquote.quickQuoteSetup.quickQuote = action.payload;
      }
    });
    builder.addCase(updateQuickQuote.rejected, (state, action) => {
      state.quickquote.status = "error";
      state.quickquote.error = action.payload;
    });
    builder.addCase(updateQuickQuoteLineDetail.pending, (state) => {
      state.quickquote.status = "pending";
    });
    builder.addCase(updateQuickQuoteLineDetail.fulfilled, (state, action) => {
      state.quickquote.status = "success";
      if (state.quickquote.quickQuoteSetup) {
        state.quickquote.quickQuoteSetup.quickQuote = action.payload;
      }
    });
    builder.addCase(updateQuickQuoteLineDetail.rejected, (state, action) => {
      state.quickquote.status = "error";
      state.quickquote.error = action.payload;
    });
    builder.addCase(updateQuickQuotePrices.pending, (state) => {
      state.quickquote.status = "pending";
    });
    builder.addCase(updateQuickQuotePrices.fulfilled, (state, action) => {
      state.quickquote.status = "success";
      if (state.quickquote.quickQuoteSetup) {
        state.quickquote.quickQuoteSetup.quickQuote = action.payload;
      }
    });
    builder.addCase(updateQuickQuotePrices.rejected, (state, action) => {
      state.quickquote.status = "error";
      state.quickquote.error = action.payload;
    });
    builder.addCase(updateQuickQuoteDueDates.pending, (state) => {
      state.quickquote.status = "pending";
    });
    builder.addCase(updateQuickQuoteDueDates.fulfilled, (state, action) => {
      state.quickquote.status = "success";
      if (state.quickquote.quickQuoteSetup) {
        state.quickquote.quickQuoteSetup.quickQuote = action.payload;
      }
    });
    builder.addCase(updateQuickQuoteDueDates.rejected, (state, action) => {
      state.quickquote.status = "error";
      state.quickquote.error = action.payload;
    });
    builder.addCase(fetchPOSearchSelectionData.pending, (state) => {
      state.poSearch.status = "pending";
    });
    builder.addCase(fetchPOSearchSelectionData.fulfilled, (state, action) => {
      state.poSearch.status = "success";
      state.poSearch.selectionData = action.payload;
    });
    builder.addCase(fetchPOSearchSelectionData.rejected, (state) => {
      state.poSearch.status = "error";
      state.poSearch.selectionData = undefined;
    });
    builder.addCase(fetchPOSearchResults.pending, (state) => {
      state.poSearch.status = "pending";
    });
    builder.addCase(fetchPOSearchResults.fulfilled, (state, action) => {
      state.poSearch.status = "success";
      state.poSearch.searchResults = action.payload;
    });
    builder.addCase(fetchPOSearchResults.rejected, (state) => {
      state.poSearch.status = "error";
      state.poSearch.searchResults = undefined;
    });
    builder.addCase(fetchDocument.pending, (state) => {
      state.docSearch.status = "pending";
    });
    builder.addCase(fetchDocument.fulfilled, (state) => {
      state.docSearch.status = "success";
    });
    builder.addCase(fetchDocument.rejected, (state) => {
      state.docSearch.status = "error";
    });
  },
});

export const selectOrderDocumentsSelectionData = (state: RootState) =>
  state.document.orders.ordersDocumentsSelectionData;

export const selectInvoiceDocumentsSelectionData = (state: RootState) =>
  state.document.invoices.invoiceDocumentsSelectionData;

export const selectPackingSlipSelectionData = (state: RootState) =>
  state.document.packingSlip.packingSlipSelectionData;

export const selectTestCertDocumentsSelectionData = (state: RootState) =>
  state.document.testCert.testCertDocumentsSelectionData;

export const selectClaimsDocumentsSelectionData = (state: RootState) =>
  state.document.claims.claimsSelectionData;

export const selectClaimsSearchSelectionData = (state: RootState) =>
  state.document.claims.claimsSearchSelectionData;

export const selectOpenLoadsSelectionData = (state: RootState) =>
  state.document.openLoads.openLoadsSelectionData;

export const selectQuoteSelectionData = (state: RootState) =>
  state.document.quote.quoteSelectionData;

export default documentSlice.reducer;
export const {
  updatePackingSlipPagination,
  testCertSort,
  packingSlipSort,
  updateOrderSearchCriteria,
  updatePackingSlipSearchCriteria,
  clearPackingSlipSearchCriteria,
  resetPackingSlipSearchCriteria,
  updateOpenLoadsSearchCriteria,
  clearOpenLoadsSearchCriteria,
  resetOpenLoadsSearchCriteria,
  updateTestCertSearchCriteria,
  resetTestCertSearchCriteria,
  clearTestCertSearchCriteria,
  updateTestCertPagination,
  updateQuotePagination,
  updateQuickQuoteSearchCriteria,
  updateQuoteSearchCriteria,
  resetQuoteSearchCriteria,
  resetQuickQuoteSearchCriteria,
  clearQuoteSearchCriteria,
  clearQuickQuoteSearchCriteria,
  quoteSort,
  quickQuoteSort,
  updateSelectedQuoteId,
  openLoadsSort,
  clearPoSearchResults,
  clearQuickQuoteView,
  clearQuickQuoteLineDetail,
  updateInvoicePagination,
  updateClaimsPagination,
  updateQuickQuotePagination,
  updateInvoiceSearchCriteria,
  invoiceSort,
  updateOrdersPagination,
  ordersSort,
  resetOrdersSearchCriteria,
  resetInvoiceSearchCriteria,
  clearDocumentErrors,
  updateQuickQuoteLineNote,
  updateQuickQuoteLineStock,
  updateQuickQuoteLineRolling,
} = documentSlice.actions;
