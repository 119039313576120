/** @jsxImportSource @emotion/react */
import React, { ReactNode, useContext, useState } from "react";
import tw from "twin.macro";
import { useAppDispatch, useAppSelector } from "../../../../app/hooks";
import Button from "../../../../components/atoms/Button";
import DatePicker from "../../../../components/atoms/DatePicker";
import Input from "../../../../components/atoms/Input";
import LengthComponent from "../../../../components/molecules/LengthComponent";
import { dateToString } from "../../../../helpers/dateHelpers";
import { selectCurrentCustomerId } from "../../../../store/customer.reducer";
import { ProductSelectionDetails } from "../../../../types/types";
import DimensionsSelectComponent from "../components/DimensionsSelectComponent";
import { updateReleaseOrderSearchTabCriteria } from "../../../../store/shipping.reducer";
import { fetchReleaseOrdersTotals } from "../../../../store/shipping.actions";
import ComboBox from "../../../../components/atoms/ComboBox";
import { PDFContext } from "./ReleaseOrders";
import { lengthToInches } from "../../../../helpers/lengthHelpers";

type Props = {
  children?: ReactNode;
};

const ReleaseOrdersSearch: React.FC<Props> = () => {
  const dispatch = useAppDispatch();
  const ctx = useContext(PDFContext);
  const customerId = useAppSelector(selectCurrentCustomerId);
  const customerParts = useAppSelector(
    (state) => state.shipping.releaseOrder.selectionData?.customerParts ?? []
  );

  const [customerPONumber, setCustomerPONumber] = useState("");
  const [salesOrderNumber, setSalesOrderNumber] = useState("");
  const [fromDate, setFromDate] = useState<Date | undefined>();
  const [toDate, setToDate] = useState<Date | undefined>();
  const [customerPartNumber, setCustomerPartNumber] = useState("");
  const [dimensions, setDimensions] = useState<ProductSelectionDetails>({
    grade: "",
    shape: "Unknown",
    height: 0,
    width: 0,
    wall: 0,
  });
  const [length, setLength] = useState({
    feet: 0,
    inches: 0,
    fraction: 0,
  });

  const resetForm = () => {
    setCustomerPONumber("");
    setSalesOrderNumber("");
    setFromDate(undefined);
    setToDate(undefined);
    setCustomerPartNumber("");
    setDimensions({
      grade: "",
      shape: "Unknown",
      height: 0,
      width: 0,
      wall: 0,
    });
    setLength({
      feet: 0,
      inches: 0,
      fraction: 0,
    });
  };

  const lengthInInches = lengthToInches(length);

  const criteria = {
    customerPONumber: customerPONumber === "" ? undefined : customerPONumber,
    fromDate: fromDate ? dateToString(fromDate) : undefined,
    toDate: toDate ? dateToString(toDate) : undefined,
    salesOrderNumber: salesOrderNumber === "" ? undefined : salesOrderNumber,
    customerPartNumber: customerPartNumber ? customerPartNumber : undefined,
    length: lengthInInches === 0 ? undefined : lengthInInches,
    grade: dimensions?.grade === "" ? undefined : dimensions?.grade,
    shape: dimensions?.shape === "Unknown" ? undefined : dimensions?.shape,
    height: dimensions?.height === 0 ? undefined : dimensions?.height,
    width: dimensions?.width === 0 ? undefined : dimensions?.width,
    decimalWallThickness: dimensions?.wall === 0 ? undefined : dimensions?.wall,
  };

  const submitHandler = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (!customerId) return;
    // Close pdf if open
    if (ctx?.file) {
      ctx?.setFile(null);
    }
    dispatch(updateReleaseOrderSearchTabCriteria(criteria));
    dispatch(
      fetchReleaseOrdersTotals({
        customerId,
        criteria: {
          ...criteria,
        },
        requestType: "search",
      })
    );
  };

  const customerPartList = [
    ...customerParts.map((item) => ({
      value: item.customerPartNumber,
      label: (
        <span>
          {item.customerPartNumber}
          <span css={tw`ml-4`}>{item.displayDescription}</span>
        </span>
      ),
    })),
  ];

  return (
    <form onSubmit={submitHandler} css={tw`text-xs`}>
      <h3 css={tw`mt-2`}>Enter any combination:</h3>
      <fieldset css={tw`mt-2`}>
        <label htmlFor="customerPO" css={tw`block`}>Customer PO #</label>
        <Input
          name="customerPONumber"
          value={customerPONumber}
          onChange={(e) => setCustomerPONumber(e.target.value)}
          css={tw`block`}
        />
      </fieldset>
      <fieldset css={tw`mt-2`}>
        <label htmlFor="fromDate" css={tw`block`}>From Due Date</label>
        <DatePicker
          css={tw`bg-white`}
          name="fromDate"
          value={fromDate}
          onChange={setFromDate}
        />
      </fieldset>
      <fieldset css={tw`mt-2`}>
        <label htmlFor="toDate" css={tw`block`}>To Due Date</label>
        <DatePicker
          css={tw`bg-white`}
          name="toDate"
          value={toDate}
          onChange={setToDate}
        />
      </fieldset>
      <fieldset css={tw`mt-2`}>
        <label htmlFor="salesOrder" css={tw`block`}>Sales Order #</label>
        <Input
          name="salesOrderNumber"
          value={salesOrderNumber}
          onChange={(e) => setSalesOrderNumber(e.target.value)}
          css={tw`block`}
        />
      </fieldset>
      {customerPartList.length > 0 && (
        <fieldset css={tw`mt-2 max-w-[80%]`}>
          <label css={tw`block`}>Customer Part #</label>
          <ComboBox
            value={customerPartNumber}
            name="customerPartNumber"
            onChange={setCustomerPartNumber}
            data={customerPartList}
          />
        </fieldset>
      )}
      <LengthComponent
        value={length}
        css={tw`mt-2`}
        onLengthChange={(len) => setLength(len)}
      />
      <DimensionsSelectComponent
        value={dimensions}
        onChange={(dim) => setDimensions(dim)}
      />
      <div css={tw`w-full text-right`}>
        <Button css={tw`mx-4 py-[2px] text-xs`} onClick={resetForm}>
          Clear
        </Button>
        <Button css={tw`m-0 py-[2px] font-normal text-xs mt-3`} type="submit">
          Search
        </Button>
      </div>
    </form>
  );
};

export default ReleaseOrdersSearch;
