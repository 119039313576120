/** @jsxImportSource @emotion/react */
import React, { ReactNode, useEffect, useState } from "react";
import tw from "twin.macro";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faSquareMinus,
  faSquarePlus,
} from "@fortawesome/free-regular-svg-icons";
import Totals from "./Totals";
import { OrderTotals, SearchCriteria } from "../../../../types/types";
import OpenOrdersDetails from "./OpenOrdersDetails";

type Props = {
  children?: ReactNode;
  order: OrderTotals;
  currentSort: Pick<SearchCriteria, "sort" | "dir">;
};

const Order: React.FC<Props> = ({ order, currentSort }) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const { poundsOrdered, poundsBalance, poundsReadyToLoad } = order;

  useEffect(() => {
    setIsExpanded(false);
  }, [order]);

  return (
    <div>
      <button
        css={tw`w-full`}
        type="button"
        onClick={() => setIsExpanded((prev) => !prev)}
      >
        <div css={tw`flex gap-4 py-1 border border-b-[#00000066]`}>
          <FontAwesomeIcon
            css={tw`mt-1 ml-1`}
            icon={isExpanded ? faSquareMinus : faSquarePlus}
          />
          <div
            css={tw`font-bold text-nucor-gray text-left grid grid-cols-4 w-7/12`}
          >
            <p css={tw`font-semibold text-nucor-gray col-span-3`}>
              <span>Ship To: </span>
              <span css={tw`uppercase`}>{order.shipToId}</span>
              <span css={tw`uppercase ml-2`}>{order.shipName}</span>
              <span css={tw`ml-4`}>{order.deliveryMode?.toUpperCase()}</span>
            </p>
            <p css={tw`uppercase col-span-1`}>
              {order.shipCity}, {order.shipState}
            </p>
          </div>
          <div css={tw`flex-1`} />
          <Totals
            totals={{ poundsOrdered, poundsBalance, poundsReadyToLoad }}
            css={tw`w-4/12 mr-8 gap-0`}
          />
        </div>
      </button>
      {isExpanded && (
        <OpenOrdersDetails order={order} currentSort={currentSort} />
      )}
    </div>
  );
};

export default Order;
