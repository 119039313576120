/** @jsxImportSource @emotion/react */
import React, { ReactNode, useContext, useEffect, useState } from "react";
import tw from "twin.macro";
import { useAppDispatch, useAppSelector } from "../../../../app/hooks";
import ButtonSelect from "../../../../components/atoms/ButtonSelect";
import Select from "../../../../components/atoms/Select";
import TabGroupSubGroup from "../../../../components/atoms/TabGroupSubGroup";
import { selectCurrentCustomerId } from "../../../../store/customer.reducer";
import {
  clearDocumentErrors,
  selectTestCertDocumentsSelectionData,
  updateTestCertSearchCriteria,
} from "../../../../store/document.reducer";
import { selectDefaultMill } from "../../../../store/user.reducer";
import { searchTestCertDocument } from "../../../../store/document.actions";
import Toast from "../../../../components/molecules/Toast";
import { PDFContext } from "./CertsList";

type TestCertBrowseCriteria = {
  myCustomers?: boolean;
  sites?: string[];
  dateRangeType?: string;
  shipToCustomerId?: string;
  customerSearchType?: boolean;
  siteKey?: string;
};

type Props = {
  children?: ReactNode;
};

const CertsBrowse: React.FC<Props> = () => {
  const dispatch = useAppDispatch();
  const customerId = useAppSelector(selectCurrentCustomerId);
  const defaultMill = useAppSelector(selectDefaultMill);
  const testCertDocumentsSelectionData = useAppSelector(
    selectTestCertDocumentsSelectionData
  );
  const { searchCriteria, error } = useAppSelector(
    (state) => state.document.testCert
  );
  const ctx = useContext(PDFContext);

  const [customerSearchType, setCustomerSearchType] = useState(
    searchCriteria.myCustomers ?? false
  );
  const [dateRangeType, setDateRangeType] = useState(
    searchCriteria.dateRangeType ?? "last30Days"
  );
  const [shipToCustomerId, setShipToCustomerId] = useState(
    searchCriteria.shipToCustomerId ?? "all"
  );
  const [site, setSite] = useState(
    searchCriteria.siteKey ?? defaultMill ?? "ALL"
  );

  const currentCriteria = {
    ...searchCriteria,
    dateRangeType,
    shipToCustomerId:
      shipToCustomerId === "all" || customerSearchType
        ? undefined
        : shipToCustomerId,
    sites:
      site === "ALL"
        ? undefined
        : testCertDocumentsSelectionData?.sites.find(
            (item) => item.key === site
          )?.sites,
  };

  useEffect(() => {
    if (!customerId || !testCertDocumentsSelectionData) return;
    // Close pdf if open
    if (ctx?.file) {
      ctx?.setFile(null);
    }
    dispatch(searchTestCertDocument({ customerId, criteria: currentCriteria }));
  }, [searchCriteria, customerId, testCertDocumentsSelectionData]);

  const onSelectionsChanged = (criteria: TestCertBrowseCriteria) => {
    if (!customerId) return;
    dispatch(updateTestCertSearchCriteria({ ...criteria, page: 1 }));
  };

  const shipTos =
    testCertDocumentsSelectionData?.shipTos.map((shipTo) => {
      return {
        value: shipTo.id,
        label: <span>{`${shipTo.id} - ${shipTo.name}`}</span>,
      };
    }) ?? [];

  return (
    <>
      {error && (
        <Toast
          type="error"
          message={error}
          onConfirm={() => dispatch(clearDocumentErrors())}
        />
      )}

      <TabGroupSubGroup title="Show">
        <Select
          minWidth="22ch"
          name="showBy"
          data={testCertDocumentsSelectionData?.customerSearchType.map(
            (item) => ({
              value: item.id,
              label: item.description,
            })
          )}
          onChange={(value: boolean) => {
            setCustomerSearchType(value);
            onSelectionsChanged({
              ...currentCriteria,
              myCustomers: value,
            });
          }}
          value={customerSearchType}
        />
      </TabGroupSubGroup>
      {!customerSearchType && (
        <TabGroupSubGroup title="By Ship To">
          <Select
            minWidth="22ch"
            maxWidth="22ch"
            name="byShipTo"
            data={[{ value: "all", label: "All" }, ...shipTos]}
            onChange={(value: string) => {
              setShipToCustomerId(value);
              onSelectionsChanged({
                ...currentCriteria,
                shipToCustomerId: value === "all" ? undefined : value,
              });
            }}
            value={shipToCustomerId}
          />
        </TabGroupSubGroup>
      )}
      <TabGroupSubGroup title="By Certificate Date">
        {testCertDocumentsSelectionData?.dateSelections.map((item, index) => (
          <ButtonSelect
            key={index}
            isActive={dateRangeType === item.id}
            onClick={() => {
              setDateRangeType(item.id);
              onSelectionsChanged({
                ...currentCriteria,
                dateRangeType: item.id,
              });
            }}
          >
            {item.description}
          </ButtonSelect>
        ))}
      </TabGroupSubGroup>
      <TabGroupSubGroup title="By Mill">
        <ButtonSelect
          isActive={site === "ALL"}
          onClick={() => {
            setSite("ALL");
            onSelectionsChanged({
              ...currentCriteria,
              siteKey: "ALL",
            });
          }}
          css={tw`block`}
        >
          All
        </ButtonSelect>
        {testCertDocumentsSelectionData?.sites.map((item, index) => (
          <ButtonSelect
            key={index}
            isActive={site === item.key}
            onClick={() => {
              setSite(item.key);
              onSelectionsChanged({
                ...currentCriteria,
                siteKey: item.key,
              });
            }}
          >
            {item.description}
          </ButtonSelect>
        ))}
      </TabGroupSubGroup>
    </>
  );
};

export default CertsBrowse;
