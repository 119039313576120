/** @jsxImportSource @emotion/react */
import React, { ReactNode } from "react";
import BrowseSearch from "../BrowseSearch";
import CertsBrowse from "./CertsBrowse";
import CertsSearch from "./CertsSearch";
import { useAppDispatch } from "../../../../app/hooks";
import { clearTestCertSearchCriteria } from "../../../../store/document.reducer";

type Props = {
  children?: ReactNode;
};

const CertsLeft: React.FC<Props> = () => {
  const dispatch = useAppDispatch();

  return (
    <BrowseSearch
      browse={<CertsBrowse />}
      search={<CertsSearch />}
      browseOnClick={() => dispatch(clearTestCertSearchCriteria())}
    />
  );
};

export default CertsLeft;
