/** @jsxImportSource @emotion/react */
import React, { ReactNode, useContext, useEffect, useState } from "react";
import tw from "twin.macro";
import { useAppDispatch, useAppSelector } from "../../../../app/hooks";
import ButtonSelect from "../../../../components/atoms/ButtonSelect";
import Select from "../../../../components/atoms/Select";
import TabGroupSubGroup from "../../../../components/atoms/TabGroupSubGroup";
import { selectCurrentCustomerId } from "../../../../store/customer.reducer";
import {
  clearDocumentErrors,
  selectOrderDocumentsSelectionData,
  updateOrderSearchCriteria,
} from "../../../../store/document.reducer";
import { selectDefaultMill } from "../../../../store/user.reducer";
import { searchOrderDocumentOrder } from "../../../../store/document.actions";
import Toast from "../../../../components/molecules/Toast";
import { PDFContext } from "./OrdersList";

type OrderDocumentBrowseCriteria = {
  myCustomers?: boolean;
  shipToCustomerId?: string;
  orderStatus?: string;
  sites?: string[];
  dateRangeType?: string;
  siteKey?: string;
};

type Props = {
  children?: ReactNode;
};

const OrdersBrowse: React.FC<Props> = () => {
  const dispatch = useAppDispatch();
  const customerId = useAppSelector(selectCurrentCustomerId);
  const orderDocumentsSelectionData = useAppSelector(
    selectOrderDocumentsSelectionData
  );
  const defaultMill = useAppSelector(selectDefaultMill);
  const { searchCriteria, error } = useAppSelector(
    (state) => state.document.orders
  );
  const ctx = useContext(PDFContext);

  const [customerSearchType, setCustomerSearchType] = useState(
    searchCriteria.myCustomers ?? false
  );
  const [shipToCustomerId, setShipToCustomerId] = useState(
    searchCriteria.shipToCustomerId ?? "all"
  );
  const [orderStatus, setOrderStatus] = useState(
    searchCriteria.orderStatus ?? "all"
  );
  const [site, setSite] = useState(
    searchCriteria.siteKey ?? defaultMill ?? "ALL"
  );
  const [dateRangeType, setDateRangeType] = useState(
    searchCriteria.dateRangeType ?? "last30Days"
  );

  const criteria = {
    ...searchCriteria,
    myCustomers: customerSearchType,
    shipToCustomerId:
      shipToCustomerId === "all" || customerSearchType
        ? undefined
        : shipToCustomerId,
    orderStatus: orderStatus === "all" ? undefined : orderStatus,
    dateRangeType,
    sites:
      site === "ALL"
        ? undefined
        : orderDocumentsSelectionData?.sites.find((item) => item.key === site)
            ?.sites,
  };

  useEffect(() => {
    if (!customerId || !orderDocumentsSelectionData) return;
    // Close pdf if open
    if (ctx?.file) {
      ctx?.setFile(null);
    }
    dispatch(searchOrderDocumentOrder({ customerId, criteria }));
  }, [searchCriteria, customerId, orderDocumentsSelectionData]);

  const onSelectionsChanged = (criteria: OrderDocumentBrowseCriteria) => {
    if (!customerId) return;
    dispatch(updateOrderSearchCriteria({ ...criteria, page: 1 }));
  };

  const shipTos =
    orderDocumentsSelectionData?.shipTos.map((shipTo) => {
      return {
        value: shipTo.id,
        label: <span>{`${shipTo.id} - ${shipTo.name}`}</span>,
      };
    }) ?? [];

  return (
    <>
      {error && (
        <Toast
          type="error"
          message={error}
          onConfirm={() => dispatch(clearDocumentErrors())}
        />
      )}
      <TabGroupSubGroup title="Show">
        <Select
          minWidth="22ch"
          name="showBy"
          data={orderDocumentsSelectionData?.customerSearchType.map((item) => ({
            value: item.id,
            label: item.description,
          }))}
          onChange={(value: boolean) => {
            setCustomerSearchType(value);
            onSelectionsChanged({
              ...criteria,
              myCustomers: value,
            });
          }}
          value={customerSearchType}
        />
      </TabGroupSubGroup>
      {!customerSearchType && (
        <TabGroupSubGroup title="By Ship To">
          <Select
            minWidth="22ch"
            maxWidth="22ch"
            name="byShipTo"
            data={[{ value: "all", label: "All" }, ...shipTos]}
            onChange={(value: string) => {
              setShipToCustomerId(value);
              onSelectionsChanged({
                ...criteria,
                shipToCustomerId: value === "all" ? undefined : value,
              });
            }}
            value={shipToCustomerId}
          />
        </TabGroupSubGroup>
      )}
      <TabGroupSubGroup title="By Order Status">
        <ButtonSelect
          isActive={orderStatus === "all"}
          onClick={() => {
            setOrderStatus("all");
            onSelectionsChanged({
              ...criteria,
              orderStatus: undefined,
            });
          }}
          css={tw`block`}
        >
          All
        </ButtonSelect>
        {orderDocumentsSelectionData?.orderStatuses.map((status) => (
          <ButtonSelect
            key={status.id}
            isActive={orderStatus === status.id}
            onClick={() => {
              setOrderStatus(status.id);
              onSelectionsChanged({
                ...criteria,
                orderStatus: status.id,
              });
            }}
          >
            {status.description}
          </ButtonSelect>
        ))}
      </TabGroupSubGroup>
      <TabGroupSubGroup title="By Mill">
        <ButtonSelect
          isActive={site === "ALL"}
          onClick={() => {
            setSite("ALL");
            onSelectionsChanged({
              ...criteria,
              siteKey: "ALL",
            });
          }}
        >
          All
        </ButtonSelect>
        {orderDocumentsSelectionData?.sites.map((item, index) => {
          return (
            <ButtonSelect
              isActive={site === item.key}
              onClick={() => {
                setSite(item.key);
                onSelectionsChanged({
                  ...criteria,
                  siteKey: item.key,
                });
              }}
              key={index}
            >
              {item.description}
            </ButtonSelect>
          );
        })}
      </TabGroupSubGroup>
      <TabGroupSubGroup title="By Order Date">
        {orderDocumentsSelectionData?.dateSelections.map((date, index) => {
          return (
            <ButtonSelect
              key={index}
              onClick={() => {
                setDateRangeType(date.id);
                onSelectionsChanged({
                  ...criteria,
                  dateRangeType: date.id,
                });
              }}
              isActive={dateRangeType === date.id}
            >
              {date.description}
            </ButtonSelect>
          );
        })}
      </TabGroupSubGroup>
    </>
  );
};

export default OrdersBrowse;
