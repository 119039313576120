import { createAsyncThunk } from "@reduxjs/toolkit";
import { AxiosError } from "axios";
import { axiosWithAuth } from "../app/axios";
import {
  CartHeader,
  CartSummaryView,
  ErrorResponse,
  ProductInstructionsInput,
  ProductInstructionsView,
  QuoteItemInput,
} from "../types/types";
import { fetchCartSummary } from "./shoppingCart.actions";
import { RootState } from "./store";

export const fetchQuoteCartSummaries = createAsyncThunk<
  CartSummaryView[],
  void,
  { rejectValue: string | ErrorResponse }
>("quoteCart/fetchQuoteCartSummaries", async (_, { rejectWithValue }) => {
  try {
    const response = await axiosWithAuth.get(
      `${process.env.REACT_APP_API_URL}/quotes`
    );
    return response.data;
  } catch (error: unknown) {
    if (error instanceof AxiosError) {
      switch (error.response?.status) {
        case 401:
          return rejectWithValue("Unauthorized");
        case 403:
          return rejectWithValue("Forbidden");
        case 404:
          return rejectWithValue("Not found");
        case 423:
          return rejectWithValue(error.response?.data);
        case 500:
          return rejectWithValue("Internal server error");
        default:
          return rejectWithValue(
            "There was an issue fetching quote cart summaries"
          );
      }
    }
    return rejectWithValue("There was an issue fetching quote cart summaries");
  }
});

export const fetchQuoteCart = createAsyncThunk<
  CartHeader,
  string,
  { rejectValue: string | ErrorResponse }
>("quoteCart/fetchQuoteCart", async (customerId, { rejectWithValue }) => {
  try {
    const response = await axiosWithAuth.get(
      `${process.env.REACT_APP_API_URL}/quotes/${customerId}`
    );
    return response.data;
  } catch (error: unknown) {
    if (error instanceof AxiosError) {
      switch (error.response?.status) {
        case 401:
          return rejectWithValue("Unauthorized");
        case 403:
          return rejectWithValue("Forbidden");
        case 404:
          return rejectWithValue(error.response?.data);
        case 423:
          return rejectWithValue(error.response?.data);
        case 500:
          return rejectWithValue("Internal server error");
        default:
          return rejectWithValue("There was an issue fetching quote cart");
      }
    }
    return rejectWithValue("There was an issue fetching quote cart");
  }
});

type FetchQuoteCartItemInstructionsRequest = {
  customerId: string;
  salesOrderItemId: number;
};

export const fetchQuoteCartItemInstructions = createAsyncThunk<
  ProductInstructionsView,
  FetchQuoteCartItemInstructionsRequest,
  { rejectValue: string | ErrorResponse }
>(
  "quoteCart/fetchQuoteCartItemInstructions",
  async (params, { rejectWithValue }) => {
    try {
      const response = await axiosWithAuth.get(
        `${process.env.REACT_APP_API_URL}/quotes/${params.customerId}/item/${params.salesOrderItemId}/instructions`
      );
      return response.data;
    } catch (error: unknown) {
      if (error instanceof AxiosError) {
        switch (error.response?.status) {
          case 400:
            return rejectWithValue(error.response?.data);
          case 401:
            return rejectWithValue("Unauthorized");
          case 403:
            return rejectWithValue("Forbidden");
          case 404:
            return rejectWithValue(error.response?.data);
          case 423:
            return rejectWithValue(error.response?.data);
          case 500:
            return rejectWithValue("Internal server error");
          default:
            return rejectWithValue(
              "There was an issue fetching quote cart item instructions"
            );
        }
      }
      return rejectWithValue(
        "There was an issue fetching quote cart item instructions"
      );
    }
  }
);

type SubmitQuoteCartRequest = {
  customerId: string;
  body: QuoteItemInput[];
};

export const submitQuoteCart = createAsyncThunk<
  CartHeader,
  SubmitQuoteCartRequest,
  { rejectValue: string | ErrorResponse }
>(
  "quoteCart/submitQuoteCart",
  async (params, { rejectWithValue, dispatch }) => {
    try {
      const response = await axiosWithAuth.post(
        `${process.env.REACT_APP_API_URL}/quotes/${params.customerId}/submit`,
        params.body
      );
      dispatch(fetchCartSummary(params.customerId));
      return response.data;
    } catch (error: unknown) {
      if (error instanceof AxiosError) {
        switch (error.response?.status) {
          case 400:
            return rejectWithValue(error.response?.data);
          case 401:
            return rejectWithValue("Unauthorized");
          case 403:
            return rejectWithValue("Forbidden");
          case 404:
            return rejectWithValue(error.response?.data);
          case 423:
            return rejectWithValue(error.response?.data);
          case 500:
            return rejectWithValue("Internal server error");
          default:
            return rejectWithValue(
              "There was an issue submitting the quote cart"
            );
        }
      }
      return rejectWithValue("There was an issue submitting the quote cart");
    }
  }
);

export const updateQuoteCartItems = createAsyncThunk<
  CartHeader,
  string,
  { rejectValue: string | ErrorResponse }
>(
  "quoteCart/updateQuoteCartItems",
  async (customerId, { rejectWithValue, getState }) => {
    try {
      const state = getState() as RootState;
      const quoteCart = state.quoteCart.currentQuoteCart;
      const itemsArr = quoteCart?.sites.reduce((acc, site) => {
        const items = site.items.map((item) => {
          return {
            id: item.id,
            poLineNumber: item.poLineNumber,
            customerPartNumber: item.customerPartNumber,
            newCustomerPartNumber: item.newCustomerPartNumber,
            requestedShippingDate: item.requestedShippingDate,
          } as QuoteItemInput;
        });
        return [...acc, ...items];
      }, [] as QuoteItemInput[]);
      const response = await axiosWithAuth.put(
        `${process.env.REACT_APP_API_URL}/quotes/${customerId}`,
        itemsArr
      );
      return response.data;
    } catch (error: unknown) {
      if (error instanceof AxiosError) {
        switch (error.response?.status) {
          case 400:
            return rejectWithValue(error.response?.data);
          case 401:
            return rejectWithValue("Unauthorized");
          case 403:
            return rejectWithValue("Forbidden");
          case 404:
            return rejectWithValue(error.response?.data);
          case 423:
            return rejectWithValue(error.response?.data);
          case 500:
            return rejectWithValue("Internal server error");
          default:
            return rejectWithValue(
              "There was an issue update a quote cart item"
            );
        }
      }
      return rejectWithValue("There was an issue update a quote cart item");
    }
  }
);

type UpdateQuoteCartItemInstructions = {
  customerId: string;
  salesOrderItemId: number;
  body: ProductInstructionsInput;
};

export const updateQuoteCartItemInstructions = createAsyncThunk<
  CartHeader,
  UpdateQuoteCartItemInstructions,
  { rejectValue: string | ErrorResponse }
>(
  "quoteCart/updateQuoteCartItemInstructions",
  async (params, { rejectWithValue, dispatch }) => {
    try {
      const response = await axiosWithAuth.put(
        `${process.env.REACT_APP_API_URL}/quotes/${params.customerId}/item/${params.salesOrderItemId}/instructions`,
        params.body
      );
      dispatch(fetchCartSummary(params.customerId));
      return response.data;
    } catch (error: unknown) {
      if (error instanceof AxiosError) {
        switch (error.response?.status) {
          case 400:
            return rejectWithValue(error.response?.data);
          case 401:
            return rejectWithValue("Unauthorized");
          case 403:
            return rejectWithValue("Forbidden");
          case 404:
            return rejectWithValue(error.response?.data);
          case 423:
            return rejectWithValue(error.response?.data);
          case 500:
            return rejectWithValue("Internal server error");
          default:
            return rejectWithValue(
              "There was an issue updating a quote cart item instruction"
            );
        }
      }
      return rejectWithValue(
        "There was an issue updating a quote cart item instruction"
      );
    }
  }
);

export const clearQuoteCart = createAsyncThunk<
  void,
  string,
  { rejectValue: string | ErrorResponse }
>(
  "quoteCart/clearQuoteCart",
  async (customerId, { rejectWithValue, dispatch }) => {
    try {
      await axiosWithAuth.delete(
        `${process.env.REACT_APP_API_URL}/quotes/${customerId}`
      );
      dispatch(fetchCartSummary(customerId));
      return;
    } catch (error: unknown) {
      if (error instanceof AxiosError) {
        switch (error.response?.status) {
          case 401:
            return rejectWithValue("Unauthorized");
          case 403:
            return rejectWithValue("Forbidden");
          case 404:
            return rejectWithValue(error.response?.data);
          case 423:
            return rejectWithValue(error.response?.data);
          case 500:
            return rejectWithValue("Internal server error");
          default:
            return rejectWithValue(
              "There was an issue clearing the quote cart"
            );
        }
      }
      return rejectWithValue("There was an issue clearing the quote cart");
    }
  }
);

type RemoveQuoteCartItemRequest = {
  customerId: string;
  salesOrderItemId: number;
};

export const removeQuoteCartItem = createAsyncThunk<
  CartHeader,
  RemoveQuoteCartItemRequest,
  { rejectValue: string | ErrorResponse }
>("quoteCart/removeQuoteCartItem", async (params, { rejectWithValue }) => {
  try {
    const response = await axiosWithAuth.delete(
      `${process.env.REACT_APP_API_URL}/quotes/${params.customerId}/item/${params.salesOrderItemId}`
    );
    return response.data;
  } catch (error: unknown) {
    if (error instanceof AxiosError) {
      switch (error.response?.status) {
        case 401:
          return rejectWithValue("Unauthorized");
        case 403:
          return rejectWithValue("Forbidden");
        case 404:
          return rejectWithValue(error.response?.data);
        case 423:
          return rejectWithValue(error.response?.data);
        case 500:
          return rejectWithValue("Internal server error");
        default:
          return rejectWithValue("There was an issue clearing the quote cart");
      }
    }
    return rejectWithValue("There was an issue clearing the quote cart");
  }
});
