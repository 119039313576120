/** @jsxImportSource @emotion/react */
import React, { ReactNode, useState } from "react";
import tw from "twin.macro";
import { useAppDispatch, useAppSelector } from "../../../../app/hooks";
import Loading from "../../../../components/atoms/Loading";
import TextButton from "../../../../components/atoms/TextButton";
import ConfirmationDialog from "../../../../components/molecules/ConfirmationDialog";
import { selectCurrentCustomerId } from "../../../../store/customer.reducer";
import {
  clearLoadCart,
  fetchReleaseOrdersExcel,
  fetchReleaseOrdersTotals,
} from "../../../../store/shipping.actions";
import { ErrorResponse, RequestStatus } from "../../../../types/types";
import Order from "./Order";
import ReleaseOrdersFooter from "./ReleaseOrdersFooter";
import ReleaseOrdersLoadCart from "./ReleaseOrdersLoadCart";
import ReleaseOrdersTable from "./ReleaseOrdersTable";
import Totals from "./Totals";
import observerable from "./observer";
import ReviewLoad from "./reviewLoad/ReviewLoad";
import Toast from "../../../../components/molecules/Toast";
import AnimatedLoadingSpinner from "../../../../components/atoms/AnimatedLoadingSpinner";

type Props = {
  children?: ReactNode;
};

const ReleaseOrdersContent: React.FC<Props> = () => {
  const dispatch = useAppDispatch();
  const customerId = useAppSelector(selectCurrentCustomerId);
  const readyToReleaseSiteTotals = useAppSelector(
    (state) => state.shipping.releaseOrder.readyToReleaseTotals
  );
  const {
    status,
    requestType,
    searchTabCriteria,
    searchCriteria,
    selectionData,
  } = useAppSelector((state) => state.shipping.releaseOrder);

  const [sort, setSort] = useState<{ sort: string; dir: "asc" | "desc" }>({
    sort: "orderDate",
    dir: "asc",
  });
  const [error, setError] = useState<ErrorResponse | string>();

  const [loadStatus, setLoadStatus] = useState<RequestStatus>("idle");
  const [showClearModal, setShowClearModal] = useState(false);
  const [showReviewLoad, setShowReviewLoad] = useState(false);
  const [excelStatus, setExcelStatus] = useState<RequestStatus>("idle");

  const clearLoadHandler = () => {
    if (!customerId) return;
    setLoadStatus("pending");
    setShowClearModal(false);
    dispatch(clearLoadCart(customerId))
      .unwrap()
      .then(() => {
        setLoadStatus("success");
        observerable.notify();
      })
      .catch(() => setLoadStatus("error"));
  };

  const downloadExcelHandler = () => {
    if (!customerId) return;
    const criteria =
      requestType === "search"
        ? {
            ...searchTabCriteria,
          }
        : {
            ...searchCriteria,
            sites:
              searchCriteria.siteKey === "ALL"
                ? undefined
                : selectionData?.sites.find(
                    (item) => item.key === searchCriteria.siteKey
                  )?.sites,
          };
    setExcelStatus("pending");
    dispatch(fetchReleaseOrdersExcel({ customerId, body: criteria }))
      .unwrap()
      .then(() => setExcelStatus("success"))
      .catch((error) => {
        setError(error);
        setExcelStatus("error");
      });
  };

  if (showReviewLoad) {
    return (
      <ReviewLoad
        onCancel={() => {
          if (customerId) {
            const criteria = {
              ...searchCriteria,
              sites:
                searchCriteria.siteKey === "ALL"
                  ? undefined
                  : selectionData?.sites.find(
                      (item) => item.key === searchCriteria.siteKey
                    )?.sites,
            };
            dispatch(
              fetchReleaseOrdersTotals({
                customerId,
                criteria,
                requestType: "browse",
              })
            );
          }
          setShowReviewLoad(false);
        }}
      />
    );
  }

  return (
    <div css={tw`text-xs`}>
      {error && (
        <Toast
          message={error}
          type="error"
          onConfirm={() => setError(undefined)}
        />
      )}
      {showClearModal && (
        <ConfirmationDialog
          onConfirm={clearLoadHandler}
          onCancel={() => setShowClearModal(false)}
          title="Clear load?"
        >
          Are you sure you want to clear this shipment load?
        </ConfirmationDialog>
      )}
      <div css={tw`w-full text-right mb-1`}>
        <TextButton onClick={() => setShowReviewLoad(true)} css={tw`mx-6`}>
          View/Release Load
        </TextButton>
        <TextButton
          disabled={loadStatus === "pending"}
          onClick={() => setShowClearModal(true)}
        >
          Clear Load
        </TextButton>
      </div>
      <div
        css={tw`flex items-center text-white bg-nucor-green uppercase font-bold py-[3px] px-2`}
      >
        <h3 css={tw`flex-1`}>Release Ready Orders</h3>
        {excelStatus === "pending" && (
          <AnimatedLoadingSpinner colorStyle="light" message="" />
        )}
        <TextButton
          disabled={excelStatus === "pending"}
          onClick={downloadExcelHandler}
          type="button"
          css={excelStatus !== "pending" && tw`text-white`}
        >
          Excel
        </TextButton>
      </div>
      <div css={tw`relative`}>
        {(status === "pending" || loadStatus === "pending") && <Loading />}
        <ReleaseOrdersLoadCart
          onIconClicked={setShowReviewLoad}
          css={tw`mt-2`}
        />
        <ReleaseOrdersTable currentSort={sort} onSort={setSort} />
        {readyToReleaseSiteTotals?.shipToTotals?.map((item) => {
          return <Order currentSort={sort} totals={item} key={item.shipToId} />;
        })}
        {readyToReleaseSiteTotals &&
        readyToReleaseSiteTotals.customerTotals.length > 0 ? (
          <>
            <div css={tw`flex py-1 w-full mt-2`}>
              <span css={tw`font-bold text-nucor-gray flex-1 text-right`}>
                Totals
              </span>
              <Totals
                siteTotals={readyToReleaseSiteTotals.customerTotals}
                css={tw`w-2/5`}
              />
            </div>
            <ReleaseOrdersFooter />
          </>
        ) : (
          <p css={tw`text-xs text-nucor-gray mt-2 ml-2`}>
            No release orders found for the selected criteria
          </p>
        )}
      </div>
    </div>
  );
};

export default ReleaseOrdersContent;
