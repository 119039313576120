/** @jsxImportSource @emotion/react */
import React, { ReactNode } from "react";
import tw from "twin.macro";
import DocumentSearchResults from "./DocumentSearchResults";
import { PDFFile } from "../../../../types/types";
import PDFEmbed from "../../../../components/molecules/PDFEmbed";

type Props = {
  children?: ReactNode;
  file: PDFFile | null;
  setFile: React.Dispatch<React.SetStateAction<PDFFile | null>>;
};

const DocumentSearchContent: React.FC<Props> = ({ file, setFile }) => {
  return (
    <div css={tw`text-xs`}>
      <div
        css={tw`bg-nucor-green text-white font-semibold py-1 px-2 uppercase flex`}
      >
        <span>Document Search</span>
        {file && (
          <button onClick={() => setFile(null)} css={tw`flex-1`}>
            Close
          </button>
        )}
      </div>

      {file ? (
        <PDFEmbed
          onCancel={() => setFile(null)}
          setFile={setFile}
          file={file}
        />
      ) : (
        <DocumentSearchResults />
      )}
    </div>
  );
};

export default DocumentSearchContent;
