/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import React, { ReactNode } from "react";
import tw from "twin.macro";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import { selectCurrentCustomerSummary } from "../../../store/customer.reducer";
import { useNavigate } from "react-router-dom";
import TextButton from "../../../components/atoms/TextButton";
import {
  updateOpenOrderSearchCriteria,
  updateReleaseOrderSearchCriteria,
} from "../../../store/shipping.reducer";
import {
  updateInvoiceSearchCriteria,
  updateQuoteSearchCriteria,
} from "../../../store/document.reducer";
import { selectCurrentAuthorities } from "../../../store/user.reducer";
import { hasAuthority } from "../../../helpers/hasAuthority";

type Props = {
  children?: ReactNode;
};

const AccountSummary: React.FC<Props> = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const selectedCustomer = useAppSelector(
    (state) => state.customer.currentCustomer
  );
  const message = useAppSelector(
    (state) => state.user.currentContext?.accessControl?.message
  );
  const currentAuthorities = useAppSelector(selectCurrentAuthorities);
  const currentCustomerSummary = useAppSelector(selectCurrentCustomerSummary);
  const user = useAppSelector(
    (state) =>
      `${state.user.currentContext?.user.firstName} ${state.user.currentContext?.user.lastName}`
  );
  const releaseOrdersSearchCriteria = useAppSelector(
    (state) => state.shipping.releaseOrder.searchCriteria
  );
  const invoicesSearchCriteria = useAppSelector(
    (state) => state.document.invoices.searchCriteria
  );
  const quoteSearchCriteria = useAppSelector(
    (state) => state.document.quote.searchCriteria
  );

  const openOrdersSearchCriteria = useAppSelector(
    (state) => state.shipping.openOrders.searchCriteria
  );

  return (
    <div css={tw`w-5/12`}>
      <p css={tw`font-bold mb-4 text-sm`}>
        {user}, Welcome to the Nucor Tubular Products Portal!
      </p>
      {message && <p css={tw`text-xs my-4 whitespace-pre-wrap`}>{message}</p>}
      {selectedCustomer?.name && (
        <>
          <p css={tw`font-bold mb-4 text-xs`}>
            Account Summary of {selectedCustomer?.name}
          </p>
        </>
      )}
      <table className="ntp-portal-table" width="80%"
        css={[
          tw`text-xs`,
          css`
            & tr td:nth-of-type(2) {
              text-align: right;
            }
          `,
        ]}
      >
        <tbody>
          {hasAuthority(["viewOrderInvoice"], currentAuthorities) && (
            <tr>
              <td>New Inquiries</td>
              <td css={tw`w-[15%]`}>
                {currentCustomerSummary ? (
                  <TextButton
                    onClick={() => {
                      dispatch(
                        updateQuoteSearchCriteria({
                          ...quoteSearchCriteria,
                          quoteStatus: "Created",
                          dateRangeType: "last1Year",
                          myCustomers: false,
                          grade: "all",
                          sort: "quoteNumber",
                          dir: "asc",
                        })
                      );
                      navigate({
                        pathname: "/portal/mydocuments/inquirylist",
                        search: selectedCustomer?.id
                          ? `?customer_id=${selectedCustomer?.id}`
                          : undefined,
                      });
                    }}
                  >
                    {currentCustomerSummary?.openInquiryCount}
                  </TextButton>
                ) : (
                  "-"
                )}
              </td>
            </tr>
          )}

          {hasAuthority(["viewOrderInvoice"], currentAuthorities) && (
            <tr>
              <td>Quoted Inquiries</td>
              <td css={tw`w-[15%]`}>
                {currentCustomerSummary ? (
                  <TextButton
                    onClick={() => {
                      dispatch(
                        updateQuoteSearchCriteria({
                          ...quoteSearchCriteria,
                          quoteStatus: "Sent",
                          dateRangeType: "last1Year",
                          grade: "all",
                          myCustomers: false,
                          sort: "quoteNumber",
                          dir: "asc",
                        })
                      );
                      navigate({
                        pathname: "/portal/mydocuments/inquirylist",
                        search: selectedCustomer?.id
                          ? `?customer_id=${selectedCustomer?.id}`
                          : undefined,
                      });
                    }}
                  >
                    {currentCustomerSummary?.quotedInquiryCount}
                  </TextButton>
                ) : (
                  "-"
                )}
              </td>
            </tr>
          )}

          {hasAuthority(["viewShippingDocs"], currentAuthorities) && (
            <tr>
              <td>Open Orders</td>
              <td css={tw`w-[15%]`}>
                {currentCustomerSummary ? (
                  <TextButton
                    onClick={() => {
                      dispatch(
                        updateOpenOrderSearchCriteria({
                          ...openOrdersSearchCriteria,
                          siteKey: "ALL",
                          dateRangeType: "all",
                          shipToCustomerId: undefined,
                          page: 1,
                          sort: "salesOrderNumber,lineNumber",
                          dir: "asc",
                        })
                      );
                      navigate({
                        pathname: "/portal/myshipping/openorders",
                        search: selectedCustomer?.id
                          ? `?customer_id=${selectedCustomer?.id}`
                          : undefined,
                      });
                    }}
                  >
                    {currentCustomerSummary?.openOrderCount}
                  </TextButton>
                ) : (
                  "-"
                )}
              </td>
            </tr>
          )}

          {hasAuthority(["viewOrderInvoice"], currentAuthorities) && (
            <tr>
              <td>Open Invoices</td>
              <td css={tw`w-[15%]`}>
                {currentCustomerSummary ? (
                  <TextButton
                    onClick={() => {
                      dispatch(
                        updateInvoiceSearchCriteria({
                          ...invoicesSearchCriteria,
                          shipToCustomerId: undefined,
                          invoiceSearchStatus: "open",
                          dateRangeType: "all",
                          sort: "invoiceDate",
                          dir: "desc",
                          page: 1,
                          myCustomers: false,
                          siteKey: "ALL",
                        })
                      );
                      navigate({
                        pathname: "/portal/mydocuments/invoicelist",
                        search: selectedCustomer?.id
                          ? `?customer_id=${selectedCustomer?.id}`
                          : undefined,
                      });
                    }}
                  >
                    {currentCustomerSummary?.openInvoiceCount}
                  </TextButton>
                ) : (
                  "-"
                )}
              </td>
            </tr>
          )}
          {hasAuthority(["createOrderQuoteLoad"], currentAuthorities) && (
            <tr>
              <td>Tons Ready to Ship</td>
              <td css={tw`w-[15%]`}>
                {currentCustomerSummary ? (
                  <TextButton
                    onClick={() => {
                      dispatch(
                        updateReleaseOrderSearchCriteria({
                          ...releaseOrdersSearchCriteria,
                          dateRangeType: "all",
                          siteKey: "ALL",
                          shipToCustomerId: "all",
                          page: 1,
                        })
                      );
                      navigate({
                        pathname: "/portal/myshipping/orderreleaselist",
                        search: selectedCustomer?.id
                          ? `?customer_id=${selectedCustomer?.id}`
                          : undefined,
                      });
                    }}
                  >
                    {currentCustomerSummary?.readyToShipWeight}
                  </TextButton>
                ) : (
                  "-"
                )}
              </td>
            </tr>
          )}
          {hasAuthority(["createOrderQuoteLoad"], currentAuthorities) && (
            <tr>
              <td>Tons Ready to Ship Past Due 30 Days</td>
              <td css={tw`w-[15%]`}>
                {currentCustomerSummary ? (
                  <TextButton
                    onClick={() => {
                      dispatch(
                        updateReleaseOrderSearchCriteria({
                          ...releaseOrdersSearchCriteria,
                          dateRangeType: "over30Days",
                          siteKey: "ALL",
                          shipToCustomerId: "all",
                          page: 1,
                        })
                      );
                      navigate({
                        pathname: "/portal/myshipping/orderreleaselist",
                        search: selectedCustomer?.id
                          ? `?customer_id=${selectedCustomer?.id}`
                          : undefined,
                      });
                    }}
                  >
                    {currentCustomerSummary?.readyToShipWeightOver30}
                  </TextButton>
                ) : (
                  "-"
                )}
              </td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  );
};

export default AccountSummary;
