/** @jsxImportSource @emotion/react */
import React, { ReactNode, useEffect, useRef } from "react";
import tw from "twin.macro";
import { useAppSelector } from "../../../../../../app/hooks";

import { CartItem } from "../../../../../../types/types";
import { numberFormatter } from "../../../../../../helpers/numberFormat";
import { formatDateToShort } from "../../../../../../helpers/dateHelpers";

type Props = {
  children?: ReactNode;
  isEven?: boolean;
  item: CartItem;
  hasItemWithPartList?: boolean;
};

const LineItem: React.FC<Props> = ({ isEven, item, hasItemWithPartList }) => {
  const usePoLineNumber = useAppSelector(
    (state) => state.customer.currentCustomer?.useItemPoLineNumber
  );

  const itemPoRef = useRef<HTMLInputElement>(null);
  const itemPoLineRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (itemPoLineRef.current) {
      itemPoLineRef.current.value = item.poLineNumber?.toString() ?? "";
    }
    if (itemPoRef.current) {
      itemPoRef.current.value = item.customerPONumber ?? "";
    }
  }, [itemPoRef, itemPoLineRef, item.poLineNumber, item.customerPONumber]);

  return (
    <>
      {item.quoteReason && (
        <tr>
          <td colSpan={100} className="text-nucor-green">
            {item.quoteReason}
          </td>
        </tr>
      )}
      <tr css={[isEven && tw`bg-[#ddd]`]}>
        <td>{item.description}</td>
        <td>{item.grade}</td>
        <td>{item.pieces}</td>
        <td>{numberFormatter(item.weight)}</td>
        <td
          css={
            (item.source === "Secondary" || item.source === "Shorts") &&
            tw`text-red-600`
          }
        >
          {item.source}
        </td>
        {usePoLineNumber && (
          <td css={tw`text-center w-[12ch]`}>{item.poLineNumber}</td>
        )}
        <td css={tw`w-[20ch] text-center`}>
          {formatDateToShort(item.requestedShippingDate)}
        </td>
        {hasItemWithPartList && (
          <td>{item.customerPartNumber ?? item.newCustomerPartNumber}</td>
        )}
      </tr>
      <tr css={isEven && tw`bg-[#ddd]`}>
        <td valign="top" colSpan={100} css={tw`text-nucor-gray max-w-[200px]`}>
          <p>Item Instructions:</p>
          {item.lengthNote && <p css={tw`text-black`}>{item.lengthNote}</p>}
          {item.notes && <p css={tw`text-black`}>{item.notes}</p>}
        </td>
      </tr>
    </>
  );
};

export default LineItem;
