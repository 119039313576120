import React, { ReactNode } from "react";
import QuoteBrowse from "./quoteBrowse";
import QuoteSearch from "./quoteSearch";
import BrowseSearch from "../BrowseSearch";
import { useAppDispatch } from "../../../../app/hooks";
import { clearQuickQuoteSearchCriteria } from "../../../../store/document.reducer";

type Props = {
  children?: ReactNode;
};

const QuoteLeft: React.FC<Props> = () => {
  const dispatch = useAppDispatch();

  return (
    <BrowseSearch
      browse={<QuoteBrowse />}
      search={<QuoteSearch />}
      browseOnClick={() => {
        dispatch(clearQuickQuoteSearchCriteria());
      }}
    />
  );
};

export default QuoteLeft;
