/** @jsxImportSource @emotion/react */
import React, { ReactNode } from "react";
import { Navigate, Route, Routes, To } from "react-router-dom";
import tw from "twin.macro";
import NavTabs from "../../components/organisms/NavTabs";
import SubNavTabs from "../../components/organisms/SubNavTabs";
import Announcements from "./announcements/Announcements";
import Users from "./users/Users";
import NotFound from "../app/NotFound";
import SyncD365 from "./sync/SyncD365";
import TierConfig from "./tierconfig/TierConfig";
import { Authority } from "../../types/types";

export type SubmenuType = {
  title: string;
  to: To;
  authority?: Authority | Authority[];
  showCustId?: boolean;
  count?: number;
};

type Props = {
  children?: ReactNode;
};

const navmenu = [{ title: "Portal Administration", to: "" }];

const submenu: SubmenuType[] = [
  { title: "Users", to: "users" },
  { title: "Announcements", to: "announcements" },
  { title: "Sync D365 Data", to: "sync", authority: "itAdmin" },
  { title: "Tier Configuration", to: "tierconfig", authority: "itAdmin" },
];

const Admin: React.FC<Props> = () => {
  return (
    <>
      <NavTabs adminMenu navmenu={navmenu} />
      <SubNavTabs hideCustomerList submenu={submenu} />
      <div css={[tw`w-full p-2`]}>
        <Routes>
          <Route path="" element={<Navigate to="users" />} />
          <Route path="users" element={<Users />} />
          <Route path="announcements" element={<Announcements />} />
          <Route path="sync" element={<SyncD365 />} />
          <Route path="tierconfig" element={<TierConfig />} />
          <Route path="*" element={<NotFound />} />
        </Routes>
      </div>
    </>
  );
};

export default Admin;
