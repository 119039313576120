/** @jsxImportSource @emotion/react */
import React, { ReactNode } from "react";
import BrowseSearch from "../../mydocuments/BrowseSearch";
import ReleaseOrdersBrowse from "./ReleaseOrdersBrowse";
import ReleaseOrdersSearch from "./ReleaseOrdersSearch";
import { useAppDispatch } from "../../../../app/hooks";
import { clearReleaseOrderSearchCriteria } from "../../../../store/shipping.reducer";

type Props = {
  children?: ReactNode;
};

const ReleaseOrdersLeft: React.FC<Props> = () => {
  const dispatch = useAppDispatch();

  return (
    <BrowseSearch
      browse={<ReleaseOrdersBrowse />}
      search={<ReleaseOrdersSearch />}
      browseOnClick={() => dispatch(clearReleaseOrderSearchCriteria())}
    />
  );
};

export default ReleaseOrdersLeft;
