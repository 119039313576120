/** @jsxImportSource @emotion/react */
import React, { ReactNode } from "react";
import BrowseSearch from "../../mydocuments/BrowseSearch";
import OpenOrdersBrowse from "./OpenOrdersBrowse";
import OpenOrdersSearch from "./OpenOrdersSearch";
import { useAppDispatch } from "../../../../app/hooks";
import { clearOpenOrderSearchCriteria } from "../../../../store/shipping.reducer";

type Props = {
  children?: ReactNode;
};

const OpenOrdersLeft: React.FC<Props> = () => {
  const dispatch = useAppDispatch();

  return (
    <BrowseSearch
      browse={<OpenOrdersBrowse />}
      search={<OpenOrdersSearch />}
      browseOnClick={() => {
        dispatch(clearOpenOrderSearchCriteria());
      }}
    />
  );
};

export default OpenOrdersLeft;
