/** @jsxImportSource @emotion/react */
import React, { ReactNode, useContext, useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../../app/hooks";
import ButtonSelect from "../../../../components/atoms/ButtonSelect";
import Select from "../../../../components/atoms/Select";
import TabGroupSubGroup from "../../../../components/atoms/TabGroupSubGroup";
import { selectCurrentCustomerId } from "../../../../store/customer.reducer";
import {
  clearDocumentErrors,
  selectOpenLoadsSelectionData,
  updateOpenLoadsSearchCriteria,
} from "../../../../store/document.reducer";
import { searchOpenLoads } from "../../../../store/document.actions";
import Toast from "../../../../components/molecules/Toast";
import { PDFContext } from "./OpenLoads";

type Props = {
  children?: ReactNode;
};

type OpenLoadsBrowseCriteria = {
  myCustomers?: boolean;
  shipToCustomerId?: string;
  orderStatus?: string;
  sites?: string[];
  dateRangeType?: string;
  siteKey?: string;
};

const OpenLoadsBrowse: React.FC<Props> = () => {
  const dispatch = useAppDispatch();
  const customerId = useAppSelector(selectCurrentCustomerId);
  const openLoadsSelectionData = useAppSelector(selectOpenLoadsSelectionData);
  const { searchCriteria, error } = useAppSelector(
    (state) => state.document.openLoads
  );
  const ctx = useContext(PDFContext);
  const [customerSearchType, setCustomerSearchType] = useState(
    searchCriteria.myCustomers ?? false
  );
  const [shipToCustomerId, setShipToCustomerId] = useState(
    searchCriteria.shipToCustomerId ?? "all"
  );
  const [site, setSite] = useState(searchCriteria.siteKey ?? "ALL");

  const shipTos =
    openLoadsSelectionData?.shipTos.map((shipTo) => {
      return {
        value: shipTo.id,
        label: <span>{`${shipTo.id} - ${shipTo.name}`}</span>,
      };
    }) ?? [];

  const criteria = {
    ...searchCriteria,
    myCustomers: customerSearchType,
    shipToCustomerId:
      shipToCustomerId === "all" || customerSearchType
        ? undefined
        : shipToCustomerId,
    sites:
      site === "ALL"
        ? undefined
        : openLoadsSelectionData?.sites.find((item) => item.key === site)
            ?.sites,
  };

  useEffect(() => {
    if (!customerId || !openLoadsSelectionData) return;
    // Close pdf if open
    if (ctx?.file) {
      ctx?.setFile(null);
    }
    dispatch(searchOpenLoads({ customerId, criteria }));
  }, [searchCriteria, customerId, openLoadsSelectionData]);

  const onSelectionsChanged = (criteria: OpenLoadsBrowseCriteria) => {
    if (!customerId) return;
    dispatch(updateOpenLoadsSearchCriteria(criteria));
  };

  return openLoadsSelectionData ? (
    <>
      {error && (
        <Toast
          type="error"
          message={error}
          onConfirm={() => dispatch(clearDocumentErrors())}
        />
      )}
      <TabGroupSubGroup title="Show">
        <Select
          minWidth="22ch"
          name="showBy"
          data={openLoadsSelectionData?.customerSearchType.map((item) => ({
            value: item.id,
            label: item.description,
          }))}
          onChange={(value: boolean) => {
            setCustomerSearchType(value);
            onSelectionsChanged({
              ...criteria,
              myCustomers: value,
            });
          }}
          value={customerSearchType}
        />
      </TabGroupSubGroup>
      {!customerSearchType && (
        <TabGroupSubGroup title="By Ship To">
          <Select
            minWidth="22ch"
            maxWidth="22ch"
            name="byShipTo"
            data={[{ value: "all", label: "All" }, ...shipTos]}
            onChange={(value: string) => {
              setShipToCustomerId(value);
              onSelectionsChanged({
                ...criteria,
                shipToCustomerId: value === "all" ? undefined : value,
              });
            }}
            value={shipToCustomerId}
          />
        </TabGroupSubGroup>
      )}
      <TabGroupSubGroup title="By Mill">
        <ButtonSelect
          isActive={site === "ALL"}
          onClick={() => {
            setSite("ALL");
            onSelectionsChanged({
              ...criteria,
              sites: undefined,
              siteKey: "ALL",
            });
          }}
        >
          All
        </ButtonSelect>
        {openLoadsSelectionData?.sites.map((item, index) => {
          return (
            <ButtonSelect
              isActive={site === item.key}
              onClick={() => {
                setSite(item.key);
                onSelectionsChanged({
                  ...criteria,
                  siteKey: item.key,
                });
              }}
              key={index}
            >
              {item.description}
            </ButtonSelect>
          );
        })}
      </TabGroupSubGroup>
    </>
  ) : null;
};

export default OpenLoadsBrowse;
