/** @jsxImportSource @emotion/react */
import React, { ReactNode, useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../../../../app/hooks";
import ButtonSelect from "../../../../components/atoms/ButtonSelect";
import Select from "../../../../components/atoms/Select";
import TabGroupSubGroup from "../../../../components/atoms/TabGroupSubGroup";
import {
  selectCurrentCustomerId,
  selectCurrentCustomerShipTos,
} from "../../../../store/customer.reducer";
import {
  selectOpenOrdersSelectionData,
  updateOpenOrderSearchCriteria,
} from "../../../../store/shipping.reducer";
import { fetchOpenOrdersTotals } from "../../../../store/shipping.actions";
import { selectDefaultMill } from "../../../../store/user.reducer";
import { PDFContext } from "./OpenOrders";

type OpenOrdersBrowseCriteria = {
  shipToCustomerId?: string;
  sites?: string[];
  dateRangeType?: string;
  siteKey?: string;
};

type Props = {
  children?: ReactNode;
};

const OpenOrdersBrowse: React.FC<Props> = () => {
  const dispatch = useAppDispatch();
  const customerId = useAppSelector(selectCurrentCustomerId);
  const defaultMill = useAppSelector(selectDefaultMill);
  const ctx = React.useContext(PDFContext);

  const { searchCriteria } = useAppSelector(
    (state) => state.shipping.openOrders
  );
  const openOrdersSelectionData = useAppSelector(selectOpenOrdersSelectionData);

  const shipToCustomerId = searchCriteria.shipToCustomerId ?? "all";
  const site = searchCriteria.siteKey ?? defaultMill ?? "ALL";
  const dateRangeType = searchCriteria.dateRangeType ?? "all";

  const shipTos = useAppSelector(selectCurrentCustomerShipTos);

  const criteria = {
    ...searchCriteria,
    shipToCustomerId: shipToCustomerId === "all" ? undefined : shipToCustomerId,
    dateRangeType: dateRangeType === "all" ? undefined : dateRangeType,
    sites:
      site === "ALL"
        ? undefined
        : openOrdersSelectionData?.sites.find((item) => item.key === site)
            ?.sites,
  };

  useEffect(() => {
    if (!customerId) return;
    // Close pdf if open
    if (ctx?.file) {
      ctx?.setFile(null);
    }
    dispatch(
      fetchOpenOrdersTotals({ customerId, criteria, requestType: "browse" })
    );
  }, [searchCriteria, customerId]);

  const onSelectionChanged = (criteria: OpenOrdersBrowseCriteria) => {
    if (!customerId) return;
    dispatch(updateOpenOrderSearchCriteria(criteria));
  };

  return (
    <>
      <TabGroupSubGroup title="By Ship To">
        <Select
          minWidth="90%"
          maxWidth="100%"
          name="byShipTo"
          data={shipTos}
          onChange={(value: string) => {
            onSelectionChanged({
              ...criteria,
              shipToCustomerId: value === "all" ? undefined : value,
            });
          }}
          value={shipToCustomerId}
        />
      </TabGroupSubGroup>
      <TabGroupSubGroup title="By Mill">
        <ButtonSelect
          isActive={site === "ALL"}
          onClick={() => {
            onSelectionChanged({
              ...criteria,
              siteKey: "ALL",
            });
          }}
        >
          All
        </ButtonSelect>
        {openOrdersSelectionData?.sites.map((item, index) => {
          return (
            <ButtonSelect
              isActive={site === item.key}
              onClick={() => {
                onSelectionChanged({
                  ...criteria,
                  siteKey: item.key,
                });
              }}
              key={index}
            >
              {item.description}
            </ButtonSelect>
          );
        })}
      </TabGroupSubGroup>
      <TabGroupSubGroup title="By Due Date">
        {openOrdersSelectionData?.dateSelections.map((date, index) => {
          return (
            <ButtonSelect
              key={index}
              onClick={() => {
                onSelectionChanged({
                  ...criteria,
                  dateRangeType: date.id === "all" ? undefined : date.id,
                });
              }}
              isActive={dateRangeType === date.id}
            >
              {date.description}
            </ButtonSelect>
          );
        })}
      </TabGroupSubGroup>
    </>
  );
};

export default OpenOrdersBrowse;
