/** @jsxImportSource @emotion/react */
import React, { ReactNode, useEffect, useRef, useState } from "react";
import tw from "twin.macro";
import { useAppDispatch, useAppSelector } from "../../../../../../app/hooks";
import {
  dateToString,
  stringToDate,
} from "../../../../../../helpers/dateHelpers";
import { CartItem } from "../../../../../../types/types";
import DatePicker from "../../../../../../components/atoms/DatePicker";
import SpecialInstructions from "./SpecialInstructions";
import { selectCurrentCustomerId } from "../../../../../../store/customer.reducer";
import { setPrimeStockSelections } from "../../../../../../store/product.reducer";
import { useNavigate } from "react-router-dom";
import Select from "../../../../../../components/atoms/Select";
import { numberFormatter } from "../../../../../../helpers/numberFormat";
import { removeQuoteCartItem } from "../../../../../../store/quoteCart.actions";
import {
  updatePoLineNumber,
  updateItemDueDate,
  updateCustomerPartNumber,
  updateNewCustomerPartNumber,
} from "../../../../../../store/quoteCart.reducer";
import { decrementQuoteCartItemCount } from "../../../../../../store/shoppingCart.reducer";
import { setRollingItemToEdit } from "../../../../../../store/rollings.reducer";
import CustomerPartNumberDialog from "../../components/CustomerPartNumberDialog";
import TextButton from "../../../../../../components/atoms/TextButton";
import { hasAuthority } from "../../../../../../helpers/hasAuthority";
import { selectCurrentAuthorities } from "../../../../../../store/user.reducer";

type Props = {
  children?: ReactNode;
  item: CartItem;
  saveCart: () => Promise<void>;
};

const LineItem: React.FC<Props> = ({ item, saveCart }) => {
  const dispatch = useAppDispatch();
  const customerId = useAppSelector(selectCurrentCustomerId);
  const currentAuthorities = useAppSelector(selectCurrentAuthorities);
  const usePoLineNumber = useAppSelector(
    (state) => state.customer.currentCustomer?.useItemPoLineNumber
  );
  const hasItemWithPartList = useAppSelector(
    (state) => state.quoteCart.currentQuoteCart?.hasItemWithPartList
  );

  const [showSpecialInstructions, setShowSpecialInstructions] = useState(false);
  const [showCustomerPartDialog, setShowCustomerPartDialog] = useState(false);

  const itemPoRef = useRef<HTMLInputElement>(null);
  const itemPoLineRef = useRef<HTMLInputElement>(null);
  const navigate = useNavigate();
  const isInternal = hasAuthority("internal", currentAuthorities);

  const removeItemHandler = () => {
    if (!customerId) return;
    const cartItem = {
      customerId,
      salesOrderItemId: item.id,
    };
    dispatch(removeQuoteCartItem(cartItem))
      .unwrap()
      .then(() => {
        dispatch(decrementQuoteCartItemCount());
      });
  };

  const editHandler = () => {
    dispatch(setPrimeStockSelections(item.productIndex));
    if (item.source === "Rolling") {
      dispatch(setRollingItemToEdit(item.id));
    }
    navigate(`/portal/stock/stocklist?customer_id=${customerId}`);
  };

  const showSpecialInstructionsHandler = async () => {
    await saveCart();
    setShowSpecialInstructions(true);
  };

  const customerPartNumberList = item.parts?.map((part) => ({
    value: part.customerPartNumber,
    label: (
      <span>
        {part.customerPartNumber}
        <span css={tw`ml-4`}>{part.customerPartDescription}</span>
      </span>
    ),
    selectedLabel: part.customerPartNumber,
  }));

  const updateNewCustomerPartNumberHandler = (value: string) => {
    dispatch(updateNewCustomerPartNumber({ id: item.id, partNumber: value }));
    setShowCustomerPartDialog(false);
  };

  useEffect(() => {
    if (itemPoLineRef.current) {
      itemPoLineRef.current.value = item.poLineNumber?.toString() ?? "";
    }
    if (itemPoRef.current) {
      itemPoRef.current.value = item.customerPONumber ?? "";
    }
  }, [itemPoRef, itemPoLineRef, item.poLineNumber, item.customerPONumber]);

  return (
    <>
      {showCustomerPartDialog && (
        <CustomerPartNumberDialog
          defaultValue={item.newCustomerPartNumber}
          onCancel={() => setShowCustomerPartDialog(false)}
          onConfirm={updateNewCustomerPartNumberHandler}
        />
      )}
      {showSpecialInstructions && (
        <SpecialInstructions
          onClose={() => setShowSpecialInstructions(false)}
          salesOrderItemId={item.id}
        />
      )}
      {item.quoteReason && (
        <tr>
          <td className="text-nucor-green" colSpan={100}>
            {item.quoteReason}
          </td>
        </tr>
      )}
      <tr>
        <td className="relative">
          <span className="peer">{item.description}</span>
          {isInternal && (
            <span
              css={tw`absolute bg-white px-2 py-1 rounded border right-2 -top-4 hidden peer-hover:block`}
            >{`${item.itemNumber} ${item.inventoryStatus}`}</span>
          )}
        </td>
        <td>{item.grade}</td>
        <td css={tw`text-right`}>{item.pieces}</td>
        <td css={tw`text-right`}>{numberFormatter(item.weight)}</td>
        <td
          css={
            (item.source === "Secondary" || item.source === "Shorts") &&
            tw`text-red-600`
          }
        >
          {item.source}
        </td>
        {usePoLineNumber && (
          <td css={tw`text-center w-[12ch]`}>
            <input
              ref={itemPoLineRef}
              type="number"
              onKeyDown={(e: React.KeyboardEvent<HTMLInputElement>) => {
                if (
                  ["Tab", "ArrowLeft", "ArrowRight", "Backspace"].includes(
                    e.key
                  )
                )
                  return;
                if (e.key === "Escape" || e.key === "Enter")
                  return e.currentTarget.blur();
                if (!/[0-9]+/.test(e.key)) return e.preventDefault();
              }}
              min={1}
              css={tw`w-[5ch]`}
              defaultValue={item.poLineNumber}
              onBlur={(e) => {
                dispatch(
                  updatePoLineNumber({
                    id: item.id,
                    lineNumber:
                      e.target.value === "" ? undefined : +e.target.value,
                  })
                );
              }}
            />
          </td>
        )}
        <td css={tw`w-[20ch] text-center`}>
          <DatePicker
            hidePrevious
            highlight
            name="date"
            maxDate={stringToDate(item.maxDueDate)}
            minDate={stringToDate(item.minDueDate)}
            value={stringToDate(item.requestedShippingDate)}
            onChange={(date) => {
              dispatch(
                updateItemDueDate({ id: item.id, date: dateToString(date) })
              );
            }}
          />
        </td>
        {hasItemWithPartList && (
          <td>
            {!item.newCustomerPartNumber && item.parts.length > 0 && (
              <Select
                minWidth="15ch"
                value={item.customerPartNumber ?? ""}
                onChange={(value: string) => {
                  dispatch(
                    updateCustomerPartNumber({
                      id: item.id,
                      customerPartNumber: value,
                    })
                  );
                }}
                name="partList"
                data={[
                  { value: "", label: <span>None</span> },
                  ...customerPartNumberList,
                ]}
              />
            )}
            {!item.customerPartNumber && (
              <TextButton
                css={
                  !item.newCustomerPartNumber &&
                  item.parts.length > 0 &&
                  tw`ml-4`
                }
                onClick={() => setShowCustomerPartDialog(true)}
              >
                {item.newCustomerPartNumber
                  ? item.newCustomerPartNumber
                  : "New Part"}
              </TextButton>
            )}
          </td>
        )}
        <td css={tw``}>
          {item.allowEdit && (<TextButton onClick={editHandler} css={tw`mx-2`}>
            Edit
          </TextButton>)}
          <TextButton onClick={removeItemHandler}>
            Remove
          </TextButton>
        </td>
      </tr>
      <tr>
        <td valign="top" colSpan={6} css={tw`text-nucor-gray max-w-[200px]`}>
          <p>Item Instructions:</p>
          {item.lengthNote && <p css={tw`text-black`}>{item.lengthNote}</p>}
          {item.notes && <p css={tw`text-black`}>{item.notes}</p>}
        </td>
        <td valign="top" colSpan={100} css={tw`text-right`}>
          {item.allowProductInstructions && (
            <button
              onClick={showSpecialInstructionsHandler}
              type="button"
              css={tw`text-red-600 font-bold underline mr-2 focus-visible:text-nucor-link-hover focus-visible:outline-none`}
            >
              Special Instructions
            </button>
          )}
        </td>
      </tr>
    </>
  );
};

export default LineItem;
