/** @jsxImportSource @emotion/react */
import React, { ReactNode } from "react";
import BrowseSearch from "../../mydocuments/BrowseSearch";
import OpenLoadsBrowse from "./OpenLoadsBrowse";
import OpenLoadsSearch from "./OpenLoadsSearch";
import { useAppDispatch } from "../../../../app/hooks";
import { clearOpenLoadsSearchCriteria } from "../../../../store/document.reducer";

type Props = {
  children?: ReactNode;
};

const OpenLoadsLeft: React.FC<Props> = () => {
  const dispatch = useAppDispatch();

  return (
    <BrowseSearch
      browse={<OpenLoadsBrowse />}
      search={<OpenLoadsSearch />}
      browseOnClick={() => dispatch(clearOpenLoadsSearchCriteria())}
    />
  );
};

export default OpenLoadsLeft;
